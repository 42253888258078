<div class="customTabs m-2">
    <div class="row">
        <div class="col-lg-9 d-flex mb-2">
            <img alt="Profile Picture" src="/assets/logos/black.svg" class="companyLogo pl-2">
            <h6 class="companyName">{{companyName}}</h6>
        </div>
        <div class="col-lg-3 mb-2 noprint" style="display: flex;">
            <div class="search-sm d-inline-block float-md-left mr-1 mb-1 align-top">
                <input id="searchBy" class="form-control" (keyup)="onSearchKeyUp($event)" placeholder="Search"
                    style="height: 25px;">
            </div>
            <div class="text-right">
                <button type="button" data-toggle="tooltip" title="Print" data-placement="top" (click)="printOpen()"
                    class="btn btn-dark default mb-1 btn-xs pt-2"> <i class="iconsminds-printer"></i>
                </button> {{ " " }}
                <button type="button" data-toggle="tooltip" title="Export Excel" data-placement="top" (click)="export()"
                    class="btn btn-dark default mb-1 btn-xs pt-2"> <i class="simple-icon-cloud-download"></i>
                </button> {{ " " }}
                <button type="button" data-toggle="tooltip" title="Refresh" data-placement="top" (click)="refresh()"
                    class="btn btn-dark default mb-1 btn-xs pt-2"> <i class="simple-icon-refresh"></i>
                </button>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="header.length > 0">
        <div class="col-lg-12 mb-2">
            <div class="innerDiv">
                <span class="innerCaption">Filter Information</span>
                <div class="row">
                    <div class="col-lg-3" *ngFor="let item of header">
                        <span *ngIf="item.display"> <b> {{item.name}}: {{item.display}} </b> </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div [class]="classGrid">
            <ngx-datatable *ngIf="!groupRowsBy" #table id="table" [loadingIndicator]="true" [columnMode]="columnMode"
                [virtualization]="true" [rows]="_rows" [limit]="limit" [ngStyle]="{ 'height': _height }"
                [headerHeight]="headerHeight" [scrollbarH]="true" [scrollbarV]="true" [footerHeight]="0"
                [sorts]="[{ prop: 'title', dir: 'asc' }]">
                <ngx-datatable-column *ngFor="let item of _columns" [width]="item.width ? item.width : 150"
                    [name]="item.name" [prop]="item.prop">
                    <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                        <span style="cursor: pointer;" (click)="cellClickGroupBy(row, item.prop, item.name)"> {{value}}
                        </span>
                    </ng-template>
                </ngx-datatable-column>
            </ngx-datatable>
            <ngx-datatable *ngIf="groupRowsBy && _rows.length > 0" [loadingIndicator]="true" #tableGroup id="table"
                [columnMode]="columnMode" [virtualization]="true" [rows]="_rows" [limit]="limit"
                [ngStyle]="{ 'height': _height }" [headerHeight]="headerHeight" [scrollbarH]="true" [scrollbarV]="true"
                [footerHeight]="0" [groupRowsBy]="groupRowsBy" [groupExpansionDefault]="groupExpansionDefault"
                [sorts]="[{ prop: 'title', dir: 'asc' }]">
                <ngx-datatable-group-header #myGroupHeader>
                    <ng-template let-group="group" let-expanded="expanded" ngx-datatable-group-header-template>
                        <div style="padding-left:5px;background: #e9e6e6;cursor: pointer;">
                            <a [class.datatable-icon-right]="!expanded" [class.datatable-icon-down]="expanded"
                                title="Expand/Collapse Group" (click)="toggleExpandGroup(group)">
                                <b style="font-size: 15px;">{{groupRowsByCaption}}: {{ group.value[0][groupRowsBy]
                                    }}</b>
                            </a>
                        </div>
                    </ng-template>
                </ngx-datatable-group-header>
                <ngx-datatable-column *ngFor="let item of _columns" [width]="item.width ? item.width : 150"
                    [name]="item.name" [prop]="item.prop">
                    <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                        <span style="cursor: pointer;" (click)="cellClickGroupBy(row, item.prop, item.name)"> {{value}}
                        </span>
                    </ng-template>
                </ngx-datatable-column>
            </ngx-datatable>
        </div>
    </div>
</div>

<style>
    .innerDiv {
        border: 1px solid #c9c7c7;
        border-radius: 5px;
        padding: 6px;
        padding-bottom: 0px;
        margin-bottom: 5px;
    }

    .innerCaption {
        position: absolute;
        cursor: text;
        font-size: 100%;
        opacity: 1;
        top: -0.4em;
        left: 1.5rem;
        z-index: 3;
        line-height: 1;
        padding: 0 1px;
        background: #f8f8f8;
        font-weight: 700;
    }
</style>