// Angular Imports
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// This Module's Components
import { InputIndentComponent } from './input-indent.component';

@NgModule({
    imports: [
        FormsModule,ReactiveFormsModule,CommonModule
    ],
    declarations: [
        InputIndentComponent,
    ],
    exports: [
        InputIndentComponent,
    ]
})
export class InputIndentModule {

}
