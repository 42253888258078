import { environment } from 'src/environments/environment';
import { UserRole } from '../shared/auth.roles';
const adminRoot = environment.adminRoot;

export interface IMenuItem {
  id?: string;
  icon?: string;
  label: string;
  to: string;
  newWindow?: boolean;
  subs?: IMenuItem[];
  roles?: UserRole[];
}

const data: IMenuItem[] = [
  {
    icon: 'iconsminds-shop-4',
    label: 'menu.dashboards',
    to: `${adminRoot}/dashboards`,
    // roles: [UserRole.Admin, UserRole.Editor],
    subs: [
      {
        icon: 'simple-icon-briefcase',
        label: 'menu.default',
        to: `${adminRoot}/dashboards/default`,
        // roles: [UserRole.Admin],
      },
    ],
  },
  {
    icon: 'iconsminds-air-balloon-1',
    label: 'Model',
    to: `${adminRoot}/vien`,
    roles: [UserRole.Admin, UserRole.Editor],
    subs: [
      {
        icon: 'iconsminds-male-2',
        label: 'menu.start',
        to: `${adminRoot}/vien/start`,
        // roles: [UserRole.Admin],
      },
      {
        icon: 'simple-icon-paper-plane',
        label: 'Customer',
        to: `${adminRoot}/vien/customer`,
        // roles: [UserRole.Admin],
      },
      {
        icon: 'iconsminds-dollar-sign-2',
        label: 'Sales',
        to: `${adminRoot}/vien/sales`,
        // roles: [UserRole.Admin],
      },
      {
        icon: 'iconsminds-notepad',
        label: 'Model Information',
        to: `${adminRoot}/vien/model`,
        // roles: [UserRole.Admin],
      },
    ],
  },
  {
    icon: 'iconsminds-money-bag',
    label: 'Sales',
    to: `${adminRoot}/Sales`,
    roles: [UserRole.Admin, UserRole.Editor],
    subs: [
      {
        icon: 'iconsminds-money-bag',
        label: 'Setup',
        to: `${adminRoot}/Sales/setup`,
        roles: [UserRole.Admin, UserRole.Editor],
        subs: [
          {
            icon: 'iconsminds-map-marker-2',
            label: 'Destinations',
            to: `${adminRoot}/Sales/setup/destinations`,
            // roles: [UserRole.Admin],
          },
          {
            icon: 'iconsminds-snow',
            label: 'Seasons',
            to: `${adminRoot}/Sales/setup/seasons`,
            // roles: [UserRole.Admin],
          },
          {
            icon: 'simple-icon-tag',
            label: 'Brand Name',
            to: `${adminRoot}/Sales/setup/brand`,
            // roles: [UserRole.Admin],
          },
          {
            icon: 'simple-icon-book-open',
            label: 'Couries Name',
            to: `${adminRoot}/Sales/setup/couries`,
            // roles: [UserRole.Admin],
          },
          {
            icon: 'iconsminds-resize',
            label: 'Size Head',
            to: `${adminRoot}/Sales/setup/size-head`,
            // roles: [UserRole.Admin],
          },
          {
            icon: 'iconsminds-full-view',
            label: 'Size Info',
            to: `${adminRoot}/Sales/setup/size-info`,
            // roles: [UserRole.Admin],
          },
          {
            icon: 'iconsminds-coins',
            label: 'Currency',
            to: `${adminRoot}/Sales/setup/currency`,
            // roles: [UserRole.Admin],
          },
          {
            icon: 'iconsminds-notepad',
            label: 'Attribute Info',
            to: `${adminRoot}/Sales/setup/attribute`,
            // roles: [UserRole.Admin],
          },
          {
            icon: 'iconsminds-check',
            label: 'Sales Accessories',
            to: `${adminRoot}/Sales/setup/accessories`,
            // roles: [UserRole.Admin],
          },
          {
            icon: 'iconsminds-duplicate-layer',
            label: 'Model Duplicate',
            to: `${adminRoot}/Sales/setup/duplicate`,
            // roles: [UserRole.Admin],
          },
          {
            icon: 'iconsminds-venn-diagram',
            label: 'Production PPD',
            to: `${adminRoot}/Sales/setup/production`,
            // roles: [UserRole.Admin],
          },
          {
            icon: 'iconsminds-letter-sent',
            label: 'Accessories PPD',
            to: `${adminRoot}/Sales/setup/accessories-ppd`,
            // roles: [UserRole.Admin],
          },
          {
            icon: 'iconsminds-letter-open',
            label: 'Amendments',
            to: `${adminRoot}/Sales/setup/sales-amendments`,
            // roles: [UserRole.Admin],
          },
          {
            icon: 'simple-icon-docs',
            label: 'Sales Duplication',
            to: `${adminRoot}/Sales/setup/sales-duplication`,
            // roles: [UserRole.Admin],
          },  
        ]
      },
      {
        icon: 'iconsminds-money-bag',
        label: 'Transection',
        to: `${adminRoot}/Sales/transection`,
        roles: [UserRole.Admin, UserRole.Editor],
        subs: [
          {
            icon: 'iconsminds-password-field',
            label: 'Claim/Discount',
            to: `${adminRoot}/Sales/setup/cliam-discount`,
            // roles: [UserRole.Admin],
          },
          {
            icon: 'simple-icon-event',
            label: 'Sample Stock',
            to: `${adminRoot}/Sales/setup/samplestock`,
            // roles: [UserRole.Admin],
          },
          {
            icon: 'iconsminds-support',
            label: 'Sales Inquiry',
            to: `${adminRoot}/Sales/setup/sales-inquiry`,
            // roles: [UserRole.Admin],
          },
          {
            icon: 'iconsminds-upgrade',
            label: 'shipment Req.',
            to: `${adminRoot}/Sales/setup/shipment-req`,
            // roles: [UserRole.Admin],
          },
          {
            icon: 'iconsminds-jacket',
            label: 'Leather pur.',
            to: `${adminRoot}/Sales/setup/frm-lth-pur-order`,
            // roles: [UserRole.Admin],
          },
          {
            icon: 'iconsminds-business-man',
            label: 'Agent',
            to: `${adminRoot}/Sales/setup/sales-agent`,
            // roles: [UserRole.Admin],
          },
          {
            icon: 'iconsminds-mens',
            label: 'Cusstomer Info',
            to: `${adminRoot}/Sales/setup/customer-info`,
            // roles: [UserRole.Admin],
          },
          {
            icon: 'iconsminds-check',
            label: 'Features',
            to: `${adminRoot}/Sales/setup/features`,
            // roles: [UserRole.Admin],
          },
          {
            icon: 'iconsminds-loudspeaker',
            label: 'Announcement',
            to: `${adminRoot}/Sales/setup/announcment`,
            // roles: [UserRole.Admin],
          },
          {
            icon: 'iconsminds-check',
            label: 'Features',
            to: `${adminRoot}/Sales/setup/features`,
            // roles: [UserRole.Admin],
          },
          {
            icon: 'iconsminds-loudspeaker',
            label: 'Announcement',
            to: `${adminRoot}/Sales/setup/announcment`,
            // roles: [UserRole.Admin],
          },
          {
            icon: 'iconsminds-loudspeaker',
            label: 'Sample Invoice',
            to: `${adminRoot}/Sales/setup/sample-inv`,
            // roles: [UserRole.Admin],
          },
        ]
      }
    ],

  },
  {
    icon: 'iconsminds-save',
    label: 'Merchandising',
    to: `${adminRoot}/Merchandising`,
    roles: [UserRole.Admin, UserRole.Editor],
    subs: []
  },
  {
    icon: 'iconsminds-scissor',
    label: 'Leather Store',
    to: `${adminRoot}/LeatherStore`,
    roles: [UserRole.Admin, UserRole.Editor],
    subs: [
      {
        icon: 'iconsminds-cow  ',
        label: 'Leather Skin',
        to: `${adminRoot}/LeatherStore/leather-skin`,
        // roles: [UserRole.Admin],
      },
      {
        icon: 'iconsminds-bookmark  ',
        label: 'Leather Article',
        to: `${adminRoot}/LeatherStore/leather-article`,
        // roles: [UserRole.Admin],
      },
      {
        icon: 'iconsminds-map-marker-2  ',
        label: 'Leather Origin',
        to: `${adminRoot}/LeatherStore/leather-origin`,
        // roles: [UserRole.Admin],
      },
      {
        icon: 'iconsminds-pantone  ',
        label: 'Leather Color',
        to: `${adminRoot}/LeatherStore/leather-color`,
        // roles: [UserRole.Admin],
      },
      {
        icon: 'iconsminds-formula  ',
        label: 'Leather Shade',
        to: `${adminRoot}/LeatherStore/leather-shade`,
        // roles: [UserRole.Admin],
      },
      {
        icon: 'iconsminds-layer-forward  ',
        label: 'Leather Category',
        to: `${adminRoot}/LeatherStore/leather-category`,
        // roles: [UserRole.Admin],
      },
      {
        icon: 'iconsminds-check  ',
        label: 'Leather Status',
        to: `${adminRoot}/LeatherStore/leather-status`,
        // roles: [UserRole.Admin],
      },
      {
        icon: 'iconsminds-loading  ',
        label: 'Leather Grade',
        to: `${adminRoot}/LeatherStore/leather-grade`,
        // roles: [UserRole.Admin],
      },
      {
        icon: 'iconsminds-conference',
        label: 'Leather Supplier',
        to: `${adminRoot}/LeatherStore/leather-supplier`,
        // roles: [UserRole.Admin],
      },
      {
        icon: 'iconsminds-newspaper',
        label: 'Leather Master',
        to: `${adminRoot}/LeatherStore/leather-master`,
        // roles: [UserRole.Admin],
      },
    ]
  },
  {
    icon: 'iconsminds-belt',
    label: 'Accessories',
    to: `${adminRoot}/Accessories`,
    roles: [UserRole.Admin, UserRole.Editor],
    subs: [
      {
        icon: 'iconsminds-money-bag',
        label: 'Transaction',
        to: `${adminRoot}/Accessories/transaction`,
        roles: [UserRole.Admin, UserRole.Editor],
        subs: [
          {
            icon: 'iconsminds-newspaper',
            label: 'Purchase Order',
            to: `${adminRoot}/Accessories/purchaseOrder`,
            // roles: [UserRole.Admin],
          },
          {
            icon: 'iconsminds-newspaper',
            label: 'Purchase Recvd',
            to: `${adminRoot}/Accessories/purchaseReceiving`,
            // roles: [UserRole.Admin],
          },
          {
            icon: 'iconsminds-newspaper',
            label: 'Purchase Return',
            to: `${adminRoot}/Accessories/purchaseReturn`,
            // roles: [UserRole.Admin],
          },
          {
            icon: 'iconsminds-newspaper',
            label: 'Accessory Issued',
            to: `${adminRoot}/Accessories/assecooryIssued`,
            // roles: [UserRole.Admin],
          },
        ]
      },
      {
        icon: 'iconsminds-money-bag',
        label: 'Requisition',
        to: `${adminRoot}/Accessories/requisition`,
        roles: [UserRole.Admin, UserRole.Editor],
        subs: [
          {
            icon: 'iconsminds-newspaper',
            label: 'Issue Slip',
            to: `${adminRoot}/Accessories/issueRequisition`,
            // roles: [UserRole.Admin],
          },
          {
            icon: 'iconsminds-newspaper',
            label: 'Purchase Slip',
            to: `${adminRoot}/Accessories/purchaseRequisition`,
            // roles: [UserRole.Admin],
          },
        ]
      },
      {
        icon: 'iconsminds-money-bag',
        label: 'Gate Pass',
        to: `${adminRoot}/Accessories/gpass`,
        roles: [UserRole.Admin, UserRole.Editor],
        subs: [
          {
            icon: 'iconsminds-newspaper',
            label: 'Issue',
            to: `${adminRoot}/Accessories/gatePass`,
            // roles: [UserRole.Admin],
          },
          {
            icon: 'iconsminds-newspaper',
            label: 'Recieving',
            to: `${adminRoot}/Accessories/gatePassRecieving`,
            // roles: [UserRole.Admin],
          },
        ]
      },
      {
        icon: 'iconsminds-money-bag',
        label: 'Setup',
        to: `${adminRoot}/Sales/setup`,
        roles: [UserRole.Admin, UserRole.Editor],
        subs: [
          {
            icon: 'iconsminds-add-file  ',
            label: 'Accessory Head',
            to: `${adminRoot}/Accessories/accessoryHead`,
            // roles: [UserRole.Admin],
          },
          {
            icon: 'iconsminds-box-close',
            label: '  Accessory Item',
            to: `${adminRoot}/Accessories/accessoryItem`,
            // roles: [UserRole.Admin],
          },
          {
            icon: 'iconsminds-coat',
            label: '  Accessory Finishing',
            to: `${adminRoot}/Accessories/accessoryFinishing`,
            // roles: [UserRole.Admin],
          },
          {
            icon: 'simple-icon-tag',
            label: '  Accessory Brand',
            to: `${adminRoot}/Accessories/accessoryBrand`,
            // roles: [UserRole.Admin],
          },
          {
            icon: 'iconsminds-pricing',
            label: '  Accessory Owns',
            to: `${adminRoot}/Accessories/accessoryOwns`,
            // roles: [UserRole.Admin],
          },
          {
            icon: 'iconsminds-pricing',
            label: '  Accessory Width',
            to: `${adminRoot}/Accessories/accessoryWidth`,
            // roles: [UserRole.Admin],
          },
          {
            icon: 'iconsminds-pantone',
            label: '  Accessory Color',
            to: `${adminRoot}/Accessories/accessoryColor`,
            // roles: [UserRole.Admin],
          },
          {
            icon: 'iconsminds-maximize',
            label: '  Accessory Size',
            to: `${adminRoot}/Accessories/accessorySize`,
            // roles: [UserRole.Admin],
          },

          {
            icon: 'iconsminds-arrow-through',
            label: '  Accessory Unit',
            to: `${adminRoot}/Accessories/accessory-unit`,
            // roles: [UserRole.Admin],
          },
          {
            icon: 'iconsminds-orientation-1',
            label: '  Slider Type',
            to: `${adminRoot}/Accessories/accessorySliderType`,
            // roles: [UserRole.Admin],
          },
          {
            icon: 'iconsminds-conference',
            label: '  Acc. Supplier',
            to: `${adminRoot}/Accessories/accessorySupplier`,
            // roles: [UserRole.Admin],
          },
          {
            icon: 'iconsminds-newspaper',
            label: '  Accessory Master',
            to: `${adminRoot}/Accessories/accessoryMaster`,
            // roles: [UserRole.Admin],
          },

        ]
      },
    ]
  },
  {
    icon: 'iconsminds-factory',
    label: 'Manufacturing',
    to: `${adminRoot}/Manufacturing`,
    roles: [UserRole.Admin, UserRole.Editor],
    subs: []
  },
  {
    icon: 'iconsminds-download-1',
    label: 'Import',
    to: `${adminRoot}/Import`,
    roles: [UserRole.Admin, UserRole.Editor],
    subs: []
  },
  {
    icon: 'simple-icon-share-alt',
    label: 'Export',
    to: `${adminRoot}/Export`,
    roles: [UserRole.Admin, UserRole.Editor],
    subs: []
  },
  {
    icon: 'iconsminds-inbox-full',
    label: 'Audit',
    to: `${adminRoot}/Audit`,
    roles: [UserRole.Admin, UserRole.Editor],
    subs: []
  },
  {
    icon: 'iconsminds-calculator',
    label: 'Accounting',
    to: `${adminRoot}/Accounting`,
    roles: [UserRole.Admin, UserRole.Editor],
    subs: []
  },
  {
    icon: 'iconsminds-check',
    label: 'Approvals',
    to: `${adminRoot}/Approvals`,
    roles: [UserRole.Admin, UserRole.Editor],
    subs: []
  },
  {
    icon: 'iconsminds-full-view',
    label: 'Resource Planing',
    to: `${adminRoot}/ResourcePlaning`,
    roles: [UserRole.Admin, UserRole.Editor],
    subs: []
  },
  {
    icon: 'iconsminds-notepad',
    label: 'DSS',
    to: `${adminRoot}/DSS`,
    roles: [UserRole.Admin, UserRole.Editor],
    subs: []
  },
  {
    icon: 'iconsminds-administrator',
    label: 'Admin',
    to: `${adminRoot}/Admin`,
    roles: [UserRole.Admin, UserRole.Editor],
    subs: [{
      icon: 'iconsminds-money-bag',
      label: 'Setup',
      to: `${adminRoot}/Admin/setup`,
      roles: [UserRole.Admin, UserRole.Editor],
      subs: [
        {
          icon: 'simple-icon-paper-plane',
          label: 'Features/update',
          to: `${adminRoot}/Admin/setup/features`,
          // roles: [UserRole.Admin],
        },
        {
          icon: 'simple-icon-paper-plane',
          label: 'Announcement',
          to: `${adminRoot}/admin/setup/seasons`,
          // roles: [UserRole.Admin],
        },
      ]
    }
    ],
  },

];
export default data;
