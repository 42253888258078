import { Component, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatatableComponent, id } from '@swimlane/ngx-datatable';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BaseComponent } from '../../@core/BaseComponent/base.component';
import { BizDatatableComponent } from '../../@core/components/biz-datatable/biz-datatable.component';
import { Enum } from '../../@core/components/config';
//import {NgbAccordion} from 'ngx-bootstrap/accordion';

@Component({
    selector: 'ppd-controller',
    templateUrl: 'ppd-controller.component.html',
})
export class PpdControllerComponent extends BaseComponent {
    headerMenu: any[] = [];
    requestMenu: any[] = [];
    ppdParams: any[] = [];
    selectHeader: any = { id: 1, css_Class: 'iconsminds-file-clipboard-file---text', menuName: 'Detail' };
    selectRequest: any = { id: 11, css_Class: 'iconsminds-file-clipboard-file---text', menuName: 'Leather Inquiry' };
    data: any = {};
    customButtonList: any[] = [];
    columnDefIP: any[] = [];
    detailList: any[] = [];
    columnDefSize: any[] = [];
    showContrast: boolean;
    columnDefContrast: any[] = [];
    constrastdtl: any[] = [];
    sizebreakerdtl: any[] = [];
    columnDefAccessories: any[] = [];
    columnDefAccessoriesAssesment: any[] = [];
    accessoriesList: any[] = [];


    dtlList: any[] = [];
    isChange: boolean = true;

    dtlListstaus: any[] = [];
    columnDefstaus: any[] = [];


    dtlListPPdExp: any[] = [];
    columnDefppdexp: any[] = [];

    columnDeftracker: any[] = [];
    dtlListtracker: any[] = [];


    selectedConversation: any = { ppdid: 0, message: [] };
    @ViewChild('contrastModal') contrastModal: TemplateRef<any>;
    @ViewChild('sizeModal') sizeModal: TemplateRef<any>;
    private tableContrast: BizDatatableComponent = null;
    message: string;
    gridColumn: any[] = [];
    gridData: any[] = [];
    isReview: boolean = false
    isAuthoized: boolean = false
    ContrastComponentInitialized(component: BizDatatableComponent) {
        this.tableContrast = component;
    }

    @ViewChild('accTable') mainTable: DatatableComponent;

    private tableSize: BizDatatableComponent = null;

    SizeComponentInitialized(component: BizDatatableComponent) {
        this.tableSize = component;
    }
    contrastmodalRef: any;
    sizemodalRef: any;
    constructor(private notification: NotificationsService, private modalService: BsModalService, private activeroute: ActivatedRoute, private newTabRoute: Router) {
        super()
    }
    async initializeForm() {
        this.myForm = this.formBuilder.group({
            'ppdid': [''],
            'fkid': [''],
            'leatherid': [''],
            'leather': [''],
            'qty': [''],
            'extra': [''],
            'total': [''],
            'fkppdLthrID': [''],
            'qty1': [''],
        })
    }

    async ngOnInit() {
        await this.initializeForm();
        this.headerMenu = [
            { id: 1, css_Class: 'iconsminds-file-clipboard-file---text', menuName: 'Detail' },
            { id: 2, css_Class: 'iconsminds-file-edit', menuName: 'Assessments' },
            { id: 3, css_Class: 'iconsminds-file-edit', menuName: 'Requests' },
            { id: 4, css_Class: 'iconsminds-library', menuName: 'Plan' },
            { id: 5, css_Class: 'iconsminds-project', menuName: 'Status' },
            { id: 6, css_Class: 'iconsminds-speach-bubble-dialog', menuName: 'Tracker' },
            { id: 7, css_Class: 'iconsminds-usb', menuName: 'Age' },
            { id: 8, css_Class: 'iconsminds-check', menuName: 'Leadger' },
            { id: 9, css_Class: 'iconsminds-power-3', menuName: 'Closing' },
            { id: 10, css_Class: 'iconsminds-bar-chart-4', menuName: 'Reports' }
        ]
        this.requestMenu = [
            { id: 11, css_Class: 'iconsminds-file-clipboard-file---text', menuName: 'Leather Inquiry and Purchasr order' },
            { id: 12, css_Class: 'iconsminds-file-edit', menuName: 'Leather Issued' },
            { id: 13, css_Class: 'iconsminds-file-clipboard-file---text', menuName: 'Accessories Purchase' },
            { id: 14, css_Class: 'iconsminds-file-edit', menuName: 'Accessories Issued' },
            { id: 15, css_Class: 'iconsminds-project', menuName: 'Shipment Request' },
            { id: 16, css_Class: 'iconsminds-project', menuName: 'Export Detail' },
        ]
        this.selectHeader = { id: 1, css_Class: 'iconsminds-file-clipboard-file---text', menuName: 'Detail' };
        setTimeout(() => {

            this.activeroute.queryParams.subscribe(async (params) => {

                if (params.ppdid) {

                    let ppdid = JSON.parse(params.ppdid)
                    this.myForm.controls['ppdid'].setValue(Number(ppdid))
                    await this.onEnter(Number(ppdid))
                }
            })
        }, 0);

        if (this.selectHeader && this.selectHeader.id === 1) {

            await this.detailConfig();

        }
        if (this.selectHeader && this.selectHeader.id === 2) {

            await this.gridConfigDetail();

        }


        this.ppdParams = [{ name: 'filed', value: 'SpecialSearch' }, { name: 'value', value: 'value' }, { name: 'UserID', value: localStorage.getItem('UserID') }, { name: 'LocationID', value: localStorage.getItem('LocationID') }]


    }

    async getDate() {
        if (this.myForm.controls['ppdid'].value > 0) {
            await this.onEnter(this.myForm.controls['ppdid'].value)
        }
    }

    async gridConfigDetail() {
        this.columnDefAccessoriesAssesment = [
            {
                name: 'ID',
                prop: 'fk_PPDAccID',
                width: 70,
                editable: false,
                type: Enum.Datatable_ControlType.Text,
            },
            {
                name: 'Job Order Acc Name ',
                prop: 'itemDescription',
                width: 350,
                editable: false,
                type: Enum.Datatable_ControlType.Text,
            },
            // {
            //     name: 'Item ID',
            //     prop: 'fkAccItemMasterID',
            //     width: 110,
            //     editable: false,                
            //     type: Enum.Datatable_ControlType.Text,
            // },
            {
                name: 'Acc Name',
                prop: 'name',
                width: 300,
                editable: false,
                type: Enum.Datatable_ControlType.Text,
            },
            // {
            //     name: 'Unit',
            //     prop: 'unit',
            //     width: 70,
            //     editable: false,
            //     type: Enum.Datatable_ControlType.Text
            // },           
            // {
            //     name: 'Unit Qty',
            //     prop: 'qty',
            //     width: 100,
            //     editable: false,
            //     type: Enum.Datatable_ControlType.Number
            // },
            {
                name: 'Total Qty',
                prop: 'orderQTy',
                width: 110,
                editable: false,
                type: Enum.Datatable_ControlType.Number
            },
            {
                name: 'Stock',
                prop: 'isStock',
                width: 70,
                editable: false,
                type: Enum.Datatable_ControlType.Text,
                default: false
            },
            {
                name: 'Remarks',
                prop: 'description',
                width: 100,
                editable: false,
                type: Enum.Datatable_ControlType.Text,
            },
            {
                name: 'Req#',
                prop: 'reqID',
                width: 100,
                editable: false,
                type: Enum.Datatable_ControlType.Text,
            },
            {
                name: 'ReqDate',
                prop: 'reqdate',
                width: 100,
                editable: false,
                type: Enum.Datatable_ControlType.Text,
            },
            {
                name: 'ReqQty',
                prop: 'already',
                width: 100,
                editable: false,
                type: Enum.Datatable_ControlType.Text,
            },
            {
                name: 'PO#',
                prop: 'fkorderid',
                width: 100,
                editable: false,
                type: Enum.Datatable_ControlType.Text,
            },
            {
                name: 'RR #',
                prop: 'fkrrid',
                width: 100,
                editable: false,
                type: Enum.Datatable_ControlType.Text,
            },
            {
                name: 'Revd/Qty',
                prop: 'revdQty',
                width: 100,
                editable: false,
                type: Enum.Datatable_ControlType.Text,
            },


        ]
    }
    PPDLeather: any;
    async setQty(id) {
        debugger
        if (Number(id) > 0) {
            debugger
            let obj = this.PPDLeather.filter(i => i.id === Number(id))[0];
            this.myForm.controls['qty1'].setValue(Number(obj.qty))

            debugger
            //await this.getSizeBreakerDetail(this.myForm.controls['jobppd'].value, id);
            debugger
            await this.gridConfigDetail();
            debugger
            await this.getAccessoriesAssesment(this.myForm.controls['ppdid'].value, id);



        }
    }


    async getAccessoriesAssesment(ppdid, leatherid) {
        debugger
        let arr: any[] = [];
        let val = 'FKPPDID=' + ppdid + '&FKPPDLthrID=' + leatherid;
        await this.get('SalesPPDAccessoriesUsed', 'getAccessoriesDetailAssesment', val, 'apiURL').then((i: any) => {
            debugger
            if (i && i.length && i.length > 0) {
                // i.map(u => {
                //     if (u.headAccessory) {
                //         u.head = this.HeadDpDn.length > 0 && this.HeadDpDn.filter(o => o.name === u.headAccessory).length > 0 ? this.HeadDpDn.filter(o => o.name === u.headAccessory)[0].id : 0;
                //       //  u.isStock = false;
                //         if (u.fkAccID)
                //             u.fkAccItemMasterID = u.fkAccID;
                //         return u
                //     }
                // })
                debugger
                this.isChange = false;
                this.dtlList = i;
            } else {
                this.dtlList = [];
            }
        })
    };

    async FillPPDLeather(FKPPDID) {

        await this.get('SalesPPDAccessoriesUsed', 'getLeather', "FKPPDID=" + FKPPDID, 'apiURL').then((i: any) => {
            if (i && i.length && i.length > 0) {
                this.PPDLeather = i;
                this.myForm.controls['fkppdLthrID'].setValue(i[0].id)
                if (this.myForm.controls['fkppdLthrID'].value > 0) {
                    this.setQty(this.myForm.controls['fkppdLthrID'].value)
                }
            } else {
                this.PPDLeather = [];
            }
        })
    };

    async FillPPDPoductionStatus(FKPPDID) {
        //  dtlListstaus: any[] = [];
        // columnDefstaus:any[]=[];
        debugger
        await this.get('PPDController', 'getPPDPoductionStatua', "PPDID=" + FKPPDID, 'apiURL').then((i: any) => {
            if (i && i.length && i.length > 0) {
                debugger

                let keys: any[] = [];
                let object = i[0];
                // && Object.keys(this.customRow).length === 0
                Object.keys(object).forEach((e: any) => {

                    let obj = {
                        name: e.toUpperCase(),
                        prop: e,
                    }

                    keys.push(obj);
                })
                debugger
                this.columnDefstaus = [...keys];


                this.dtlListstaus = i;
            } else {
                this.dtlListstaus = [];
            }
        })
    };

    async FillPPDTracker(FKPPDID) {
        //  dtlListstaus: any[] = [];
        // columnDefstaus:any[]=[];
        debugger
        await this.get('PPDController', 'getPPDPoductionTracker', "PPDID=" + FKPPDID, 'apiURL').then((i: any) => {
            if (i && i.length && i.length > 0) {
                debugger

                let keys: any[] = [];
                let object = i[0];
                // && Object.keys(this.customRow).length === 0
                Object.keys(object).forEach((e: any) => {

                    let obj = {
                        name: e.toUpperCase(),
                        prop: e,
                    }

                    keys.push(obj);
                })
                debugger
                this.columnDeftracker = [...keys];


                this.dtlListtracker = i;
            } else {
                this.dtlListtracker = [];
            }
        })
    };


    getsTimeLine: any;
    async getTimeLine(FKPPDID) {
        //  let val = 'OrderID=' + this.OrderIDNo + '&UserType=' + localStorage.getItem('userID');
        debugger
        await this.get('PPDController', 'getOrderTimeLine', "PPDID=" + FKPPDID, 'apiURL').then((i: any) => {
            debugger
            if (i && i.length && i.length > 0) {
                let OrderTimeLine = [];
                OrderTimeLine = i;
                this.getsTimeLine = OrderTimeLine;
            } else {
                this.getsTimeLine = []
            };
        });
    };


    async FillPPDExps(FKPPDID) {
        //  dtlListstaus: any[] = [];
        // columnDefstaus:any[]=[];
        debugger
        await this.get('PPDController', 'getPPDPoductionExp', "PPDID=" + FKPPDID, 'apiURL').then((i: any) => {
            if (i && i.length && i.length > 0) {
                debugger

                let keys: any[] = [];
                let object = i[0];
                // && Object.keys(this.customRow).length === 0
                Object.keys(object).forEach((e: any) => {

                    let obj = {
                        name: e.toUpperCase(),
                        prop: e,
                    }

                    keys.push(obj);
                })
                debugger
                this.columnDefppdexp = [...keys];


                this.dtlListPPdExp = i;
            } else {
                this.dtlListPPdExp = [];
            }
        })
    };
    async onEnter(ppdid) {
        debugger
        if (ppdid > 0) {

            let userid = localStorage.getItem('UserID');
            let locationid = localStorage.getItem('LocationID');
            let val = "mfiled=ppdid" + "&" + "fvalue=" + ppdid + '&' + 'UserID=' + userid + '&' + 'LocationID=' + locationid;

            await this.get('SalesPPD', 'getSearchby', val, 'apiURL').then((i: any) => {
                debugger
                if (i && i.length && i.length > 0) {

                    this.isReview = true;
                    this.isAuthoized = true;
                    
                    this.data = i[0];
                    this.getdetail(ppdid);
                } else {

                    this.isReview = false;
                    this.isAuthoized = false;
                }
            })
        }
    }

    async getdetail(FKPPDID) {
        debugger
        await this.get('SalesPPD', 'getPPDLeatherSearchby', "FKPPDID=" + FKPPDID, 'apiURL').then((i: any) => {
            debugger
            if (i && i.length && i.length > 0) {
                debugger
                i.map(o => {
                    o.deliveryDate = o.deliveryDate.substring(0, 10);
                    return o;
                })
                debugger
                this.detailList = i;
                debugger
            } else {
                this.detailList = [];

            }
        })
    }

    async detailConfig() {
        this.customButtonList = [{ icon: 'iconsminds-line-chart-3', prop: 'contrast', name: 'Contrast' },
        { name: 'Size Breaker', icon: 'iconsminds-duplicate-layer', prop: 'size' },
        { name: 'Accessories', icon: 'iconsminds-belt', prop: 'accessory' }];
        this.columnDefIP = [
            {
                name: 'Item',
                prop: 'leather',
                type: Enum.Datatable_ControlType.Text,
                width: 280,
                editable: false,
            },
            {
                name: 'Cust Name',
                prop: 'lthrNameUsedByCust',
                type: Enum.Datatable_ControlType.Text,
                width: 130,
                editable: false
            },
            {
                name: 'Cust ColorName',
                prop: 'lthrColorUsedByCust',
                width: 130,
                type: Enum.Datatable_ControlType.Text,
                editable: false
            },
            {
                name: 'Currency',
                prop: 'curName',
                width: 90,
                type: Enum.Datatable_ControlType.Text,
                editable: false
            },
            {
                name: 'Rate',
                prop: 'rate',
                width: 60,
                type: Enum.Datatable_ControlType.Text,
                editable: false
            },
            {
                name: 'Main',
                prop: 'mainAverage',
                width: 70,
                type: Enum.Datatable_ControlType.Text,
                editable: false
            },
            {
                name: 'Contrast',
                prop: 'contrastAverage',
                width: 70,
                type: Enum.Datatable_ControlType.Text,
                editable: false
            },
            {
                name: 'Pcs',
                prop: 'qty',
                width: 70,
                type: Enum.Datatable_ControlType.Text,
                editable: false
            },
            {
                name: 'Requirement',
                prop: 'totalSqft',
                width: 70,
                type: Enum.Datatable_ControlType.Text,
                editable: false
            },
            {
                name: 'Del/Date',
                prop: 'deliveryDate',
                width: 160,
                type: Enum.Datatable_ControlType.Text,
                editable: false
            },
        ];
    }

    async ContrastGridConfig() {
        this.showContrast = true;
        this.columnDefContrast = [
            {
                name: 'Item',
                prop: 'leather',
                type: Enum.Datatable_ControlType.Text,
                width: 250,
                editable: false,
            },
            {
                name: 'Customer Leather Name',
                prop: 'lthrNameUsedByCust',
                type: Enum.Datatable_ControlType.Text,
                width: 180,
                editable: false
            },
            {
                name: 'Customer ColorName',
                prop: 'lthrColorUsedByCust',
                width: 180,
                type: Enum.Datatable_ControlType.Text,
                editable: false
            },
            {
                name: 'Type',
                prop: 'fkTypeID',
                width: 100,
                type: Enum.Datatable_ControlType.Text,
                editable: false
            },
            {
                name: 'Remarks',
                prop: 'remarks',
                width: 150,
                type: Enum.Datatable_ControlType.Text,
                editable: false
            }
        ];
    }

    async SizeGridConfig() {
        this.columnDefSize = [
            {
                name: 'Size',
                prop: 'name',
                type: Enum.Datatable_ControlType.Text,
            },
            {
                name: 'Qty',
                prop: 'qty',
                type: Enum.Datatable_ControlType.Text,
                width: 100,
                editable: false,
            },
            {
                name: 'Per%',
                prop: 'percentage',
                type: Enum.Datatable_ControlType.Text,
                width: 100,
                editable: false
            },
            {
                name: 'Extra',
                prop: 'extraQty',
                type: Enum.Datatable_ControlType.Text,
                width: 100,
                editable: false
            },
            {
                name: 'Remarks',
                prop: 'remarks',
                width: 150,
                type: Enum.Datatable_ControlType.Text,
                editable: false
            }
        ];
    }

    async gridConfigAccessoriesDetail() {
        this.columnDefAccessories = [
            {
                name: 'Accessory Name',
                prop: 'itemDescription',
                width: 170,
            },
            {
                name: 'Unit',
                prop: 'unit',
                width: 70,
            },
            {
                name: 'Size',
                prop: 'fkSizeID',
                width: 100,
            },
            {
                name: 'unitQty',
                prop: 'qty',
                width: 70,
            },
            {
                name: 'Total REquired Qty',
                prop: 'orderQTy',
                width: 70,
            },
            {
                name: 'Acc/Req#',
                prop: 'reqID',
                width: 70,
            },
            {
                name: 'Remarks',
                prop: 'description',
                width: 130,
            },

        ]
    }

    async getAccessoriesDetail(ppdid, leatherid) {
        debugger
        let val = 'FKPPDID=' + ppdid + '&FKPPDLthrID=' + leatherid;
        debugger
        await this.get('SalesPPDAccessoriesUsed', 'getAccessoriesDetail', val, 'apiURL').then((i: any) => {
            debugger
            if (i && i.length && i.length > 0) {
                this.accessoriesList = i;
            } else {
                this.accessoriesList = [];
                this.notification.create('Error', "Accessories Not Found.!",
                    NotificationType.Error, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false });
            }
        })
    };

    async ReviewSave() {

        if (this.myForm.controls['ppdid'].value > 0 && this.isReview) {

            let data = 'PPDID=' + this.myForm.controls['ppdid'].value + '&ReviewBy=' + localStorage.getItem('UserID');

            await this.get('SalesPPD', 'PPDReviewd', data, 'apiURL').then(async (o) => {

                if (o && o.message === "record successfully updated") {
                    await this.onEnter(this.myForm.controls['ppdid'].value);

                }
                else if (o && o.message) {

                    this.notification.create('Error', o.message,
                        NotificationType.Error, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false });
                } else {
                    this.notification.create('Error', o,
                        NotificationType.Error, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false });
                }
            }).catch(e => {

                this.notification.create('Error', e.message,
                    NotificationType.Error, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false });
            })
        } else {
            this.notification.create('Error', "PPD Not Found!",
                NotificationType.Error, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false });
        }
    }

    async AuthoizedSave() {
        debugger
        if (this.myForm.controls['ppdid'].value > 0 && this.isAuthoized) {

            let data = 'PPDID=' + this.myForm.controls['ppdid'].value + '&AuthoizeBy=' + localStorage.getItem('UserID');

            await this.get('SalesPPD', 'PPDAuthorized', data, 'apiURL').then(async (o) => {

                if (o && o.message === "record successfully updated") {
                    await this.onEnter(this.myForm.controls['ppdid'].value);

                }
                else if (o && o.message) {

                    this.notification.create('Error', o.message,
                        NotificationType.Error, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false });
                } else {
                    this.notification.create('Error', o,
                        NotificationType.Error, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false });
                }
            }).catch(e => {

                this.notification.create('Error', e.message,
                    NotificationType.Error, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false });
            })
        } else {
            this.notification.create('Error', "PPD Not Found!",
                NotificationType.Error, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false });
        }
    }


    toggleExpandGroup(group) {
        this.mainTable.groupHeader.toggleExpandGroup(group);
    }

    async onCustomButtonClick(e) {

        if (e && e.row && e.row.id > 0) {

            this.myForm.controls['fkid'].setValue(e.row.id);
            this.myForm.controls['leatherid'].setValue(e.row.fkMasterLthrID);
            this.myForm.controls['leather'].setValue(e.row.leather);
            if (e.item && e.item.prop === "contrast") {
                let val = e.row;
                this.constrastdtl = [];
                this.contrastmodalRef = this.modalService.show(this.contrastModal, {
                    animated: true,
                    backdrop: 'static',
                    class: 'modal-lg',
                });
                await this.getContrastDetail(this.myForm.controls['ppdid'].value, val.id);
                await this.ContrastGridConfig();
            }
            if (e.item && e.item.prop === "size") {
                let val = e.row;
                this.sizebreakerdtl = [];
                this.sizemodalRef = this.modalService.show(this.sizeModal, {
                    animated: true,
                    backdrop: 'static',
                    class: 'modal-lg',
                });
                await this.getSizeBreakerDetail(this.myForm.controls['ppdid'].value, val.id);
                await this.SizeGridConfig();
                await this.setFooterValues();
            }

            if (e.item && e.item.prop === "accessory") {
                debugger
                await this.getAccessoriesDetail(this.myForm.controls['ppdid'].value, e.row.id)
                await this.gridConfigAccessoriesDetail();

            }
        } else {
            this.notification.create('Error', "Record Not Found.",
                NotificationType.Error, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false });
        }

    }

    async getContrastDetail(PPDID, FKMainLthrID) {

        let val = 'PPDID=' + PPDID + '&FKMainLthrID=' + FKMainLthrID + '&UserID=' + localStorage.getItem('UserID') + '&LocationID=' + localStorage.getItem('LocationID');
        await this.get('SalesPPDLthrContrast', 'getSearchby', val, 'apiURL').then(i => {
            if (i && i.length && i.length > 0) {
                this.constrastdtl = i
            }
        })
    }
    async getSizeBreakerDetail(PPDID, FKMainLthrID) {

        let val = 'PPDID=' + PPDID + '&FKPPDLthrID=' + FKMainLthrID + '&UserID=' + localStorage.getItem('UserID') + '&LocationID=' + localStorage.getItem('LocationID');
        await this.get('SalesPPDSizeBreakup', 'getSearchby', val, 'apiURL').then(i => {
            if (i && i.length && i.length > 0) {
                this.sizebreakerdtl = i
            }
        })
    }
    async setFooterValues() {
        if (this.tableSize && this.tableSize.rows && this.tableSize.rows.length && this.tableSize.rows.length > 0) {
            let qty = 0;
            let extra = 0
            let total;
            this.tableSize.rows.map(o => {
                if (o.qty) {
                    qty += Number(o.qty);
                }
                if (o.extraQty) {
                    extra += Number(o.extraQty);
                }
            })
            total = qty > 0 && extra > 0 ? qty + extra : '';

            this.myForm.controls['qty'].setValue(qty);
            this.myForm.controls['extra'].setValue(extra);
            this.myForm.controls['total'].setValue(total);
        }
    }

    async setSelectheader(item) {
        this.selectHeader = item;
        if (item.id === 3) {
            await this.getPPDRequestDetail("Leather Inquiry");
        }
        if (item.id === 2) {
            debugger
            await this.FillPPDLeather(this.myForm.controls['ppdid'].value);
        }
        if (item.id === 5) {
            debugger
            await this.FillPPDPoductionStatus(this.myForm.controls['ppdid'].value);
        }
        if (item.id === 6) {
            debugger
            await this.FillPPDTracker(this.myForm.controls['ppdid'].value);
        }
        if (item.id === 7) {
            debugger
            await this.getTimeLine(this.myForm.controls['ppdid'].value);
        }
        if (item.id === 8) {
            debugger
            await this.FillPPDExps(this.myForm.controls['ppdid'].value);
        }
    }


    async setSelectRequest(item) {
        this.selectRequest = item;
    }
    getSum(group) {
        let sum = 0;
        if (group && group.value && group.value.length > 0) {
            group.value.map(y => {
                sum += Number(y.qty)
            })
        }
        return sum;
    }
    ppdRequestList: any[] = [];
    async getPPDRequestDetail(Tab) {
        debugger
        if (this.myForm.controls['ppdid'].value > 0 && Tab) {
            this.ppdRequestList = [];
            this.gridColumn = [];
            this.gridData = [];
            let val = 'tType=' + Tab + '&PPDID=' + this.myForm.controls['ppdid'].value + '&SegmentID=' + localStorage.getItem('LocationID') + '&UserID=' + localStorage.getItem('UserID');

            await this.get('PPDController', 'getPPDRequestDetail', val, 'apiURL').then(i => {

                if (i && i.length && i.length > 0) {

                    this.ppdRequestList = i;
                    let keys: any[] = [];
                    let object = i[0];
                    // && Object.keys(this.customRow).length === 0
                    Object.keys(object).forEach((e: any) => {
                        if (e !== "formName") {

                            let obj = {
                                name: e.toUpperCase(),
                                prop: e,
                            }

                            keys.push(obj);
                        }
                    })

                    this.gridColumn = [...keys];

                    this.gridData = i;

                } else {

                    this.ppdRequestList = [];
                    this.gridColumn = [];
                    this.gridData = [];
                    this.notification.create('Error', "No Records Found!",
                        NotificationType.Error, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false });
                }
            })
        } else {
            this.notification.create('Error', "PPD Not Found!",
                NotificationType.Error, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false });
        }
    }

    async onTabClick(e) {
        debugger

        if (e && e.target && e.target.innerText === "Leather Inquiry") {
            let tabName = e.target.innerText;
            await this.getPPDRequestDetail(tabName);
        }
        if (e && e.target && e.target.innerText === "Leather Issued") {
            let tabName = e.target.innerText;
            await this.getPPDRequestDetail(tabName);
        }
        if (e && e.target && e.target.innerText === "Accessories Purchase") {
            let tabName = e.target.innerText;
            debugger
            await this.getPPDRequestDetail(tabName);
        }
        if (e && e.target && e.target.innerText === "Accessories Issued") {
            let tabName = e.target.innerText;
            await this.getPPDRequestDetail(tabName);
        }
        if (e && e.target && e.target.innerText === "Shipment Request") {
            let tabName = e.target.innerText;
            await this.getPPDRequestDetail(tabName);
        }

        if (e && e.target && e.target.innerText === "Export Detail") {
            let tabName = e.target.innerText;
            await this.getPPDRequestDetail(tabName);
        }
    }

    async Action(e) {

    }

    async isNew(e) {
        let url: any;
        if (e === "Leather Inquiry") {
            url = this.newTabRouter.serializeUrl(this.newTabRouter.createUrlTree(['/APP/104/104102/104102101']));

        }
        if (e === "Leather Issued") {
            url = this.newTabRouter.serializeUrl(this.newTabRouter.createUrlTree(['/APP/104/104104/104104104']));

        }
        if (e === "Accessories Purchase") {
            url = this.newTabRouter.serializeUrl(this.newTabRouter.createUrlTree(['/APP/105/105102/105102101']));

        }
        if (e === "Accessories Issued") {
            url = this.newTabRouter.serializeUrl(this.newTabRouter.createUrlTree(['/APP/105/105103/105103101']));

        }
        if (e === "Shipment Request") {
            url = this.newTabRouter.serializeUrl(this.newTabRouter.createUrlTree(['/APP/103/103104/103104101']));

        }
        window.open(url, '_blank');
    }

}
