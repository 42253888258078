<!--component html goes here -->
<div *ngIf="design === 1" class="form-group inputContainer">
    <div
        [ngClass]=" (controlEl && controlEl.errors && controlEl.errors?.invalid && (controlEl.dirty || controlEl.touched)) || this.dirtytext  ? 'border-outline-invalid invalid' : 'border-outline-valid'">
        <select class="form-control" (keyup.backspace)="ondelete($event)" (keyup.delete)="ondelete($event)"
            [disabled]="disabled" [(ngModel)]="value" #select="ngModel">
            <option *ngFor="let item of data" [value]="item[valueMember]">{{item[displayMember]}}</option>
        </select>
    </div>
    <span
        *ngIf="(controlEl && controlEl.errors && controlEl.errors?.invalid && (controlEl.dirty || controlEl.touched)) || this.dirtytext"
        style="float: right;
        z-index: 4;
        margin-top: -23px;
        margin-right: 20px;">
        <!-- Condition to show Tooltip -->
        <span class="tooltipss" type="button">
            <i class="simple-icon-exclamation" aria-hidden="true"></i>
        </span>
    </span>
    <span for="texto">{{caption}}</span>
</div>

<label *ngIf="design === 2" class="form-group has-float-label">
    <div
        [ngClass]=" (controlEl && controlEl.errors && controlEl.errors?.invalid && (controlEl.dirty || controlEl.touched)) || this.dirtytext  ? 'border-outline-invalid invalid' : 'border-outline-valid'">
        <select class="form-control" (keyup.backspace)="ondelete($event)" (keyup.delete)="ondelete($event)"
            [disabled]="disabled" [(ngModel)]="value" (ngModelChange)="Modelchange()" #select="ngModel">
            <option *ngFor="let item of data" [value]="item[valueMember]">{{item[displayMember]}}</option>
        </select>
    </div>
    <span
        *ngIf="(controlEl && controlEl.errors && controlEl.errors?.invalid && (controlEl.dirty || controlEl.touched)) || this.dirtytext"
        style="float: right;
        z-index: 4;
        margin-top: -23px;
        margin-right: 20px;">
        <!-- Condition to show Tooltip -->
        <span class="tooltipss" type="button">
            <i class="simple-icon-exclamation" aria-hidden="true"></i>
            <!-- <span class="tooltiptextss">
                <i class="fas fa-exclamation-triangle" style="color: red;font-size: 12px ;padding-right: 2px ;"></i>
                {{errMsg}}
            </span> -->
        </span>
    </span>
    <span for="texto">{{caption}}</span>
</label>

<label *ngIf="design === 4" class="form-group has-float-label">
    <div style="    display: flex;
    border: 1px solid #d7d7d7;
    border-radius: 5px;"
        [ngClass]=" (controlEl && controlEl.errors && controlEl.errors?.invalid && (controlEl.dirty || controlEl.touched)) || this.dirtytext  ? 'border-outline-invalid invalid' : 'border-outline-valid'">
        <select class="form-control listerselect" (keyup.backspace)="ondelete($event)" (keyup.delete)="ondelete($event)"
            style="border: none;" [disabled]="disabled" [(ngModel)]="value" (ngModelChange)="Modelchange()"
            #select="ngModel">
            <option *ngFor="let item of data" [value]="item[valueMember]">{{item[displayMember]}}</option>
        </select>
        <span style="padding-right: 5px;padding-top: 5px;cursor: pointer;background: #fff;
        border-radius: 5px;" (click)="openlister()"><i class="simple-icon-magnifier"></i></span>
    </div>
    <span
        *ngIf="(controlEl && controlEl.errors && controlEl.errors?.invalid && (controlEl.dirty || controlEl.touched)) || this.dirtytext"
        style="float: right;
        z-index: 4;
        margin-top: -23px;
        margin-right: 20px;">
        <!-- Condition to show Tooltip -->
        <span class="tooltipss" type="button">
            <i class="simple-icon-exclamation" aria-hidden="true"></i>
            <!-- <span class="tooltiptextss">
                <i class="fas fa-exclamation-triangle" style="color: red;font-size: 12px ;padding-right: 2px ;"></i>
                {{errMsg}}
            </span> -->
        </span>
    </span>
    <span for="texto">{{caption}}</span>
</label>

<div *ngIf="design === 3" class="listerDropdown">
    <select class="listerselect" [ngStyle]="{ 'width': _width }" (keyup.backspace)="ondelete($event)"
        (keyup.delete)="ondelete($event)" style="border: none;" [disabled]="disabled" [(ngModel)]="value"
        (ngModelChange)="Modelchange()" #select="ngModel">
        <option *ngFor="let item of data" [value]="item[valueMember]">{{item[displayMember]}}</option>
    </select>
    <!-- <select class="listerselect" [(ngModel)]="value"
        style="background: transparent;border: none;width: -webkit-fill-available;">
        <option *ngFor="let i of data" [value]="item[valueMember]">
            {{item.displayMember}}
        </option>
    </select> -->
    <span style="padding-left: 4px; cursor: pointer;" (click)="openlister()"><i
            class="simple-icon-magnifier"></i></span>
</div>

<div *ngIf="design === 6" class="listerDropdown">
    <div class="select-editable">
        <select class="listerselect listerselected" (keyup.backspace)="ondelete($event)"
            (keyup.delete)="ondelete($event)" [ngStyle]="{ 'width': _width }" style="border: none;"
            [disabled]="disabled" [(ngModel)]="value" (ngModelChange)="Modelchange()" #select="ngModel">
            <option *ngFor="let item of data" [value]="item[valueMember]">{{item[displayMember]}}</option>
        </select>
        <input type="text" name="format" value="" />
    </div>
    <span style="padding-left: 4px; cursor: pointer;" (click)="openlister()"><i
            class="simple-icon-magnifier"></i></span>
</div>

<div *ngIf="design === 5">
    <!-- [ngStyle]="{ 'width': _width }" -->
    <select style="border: none;width: 100%;" (keyup.backspace)="ondelete($event)" (keyup.delete)="ondelete($event)"
        [disabled]="disabled" [(ngModel)]="value" (ngModelChange)="Modelchange()" #select="ngModel">
        <option *ngFor="let item of data" [value]="item[valueMember]">{{item[displayMember]}}</option>
    </select>
</div>

<template #dropdownlookup></template>
<!-- <biz-lookup #lookup [rows]="data" [columns]="listerColumns" [type]="4" [selectedRows]="selectedRows" (onClick)="alertClick($event)"></biz-lookup> -->

<style>
    .listerDropdown {
        display: flex;

    }

    .listerselect {
        -webkit-appearance: none;
        -moz-appearance: none;
        text-indent: 1px;
        text-overflow: '';
    }

    .listerselected option {
        display: none;
    }

    .select-editable {
        position: relative;
        width: 120px;
        height: 18px;
    }

    .select-editable select {
        position: absolute;
        top: 0px;
        left: 0px;
        font-size: 14px;
        border: none;
        width: 120px;
        margin: 0;
    }

    .select-editable input {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100px;
        padding: 1px;
        font-size: 12px;
        border: none;
    }

    .select-editable select:focus,
    .select-editable input:focus {
        outline: none;
    }
</style>