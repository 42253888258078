import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BaseService } from '../@core/Services/base.service';
import { ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
    selector: 'report-viewer',
    templateUrl: 'report-viewer.component.html',
})
export class ReportViewerComponent implements OnInit {
    pdfFile: any;
    constructor(public sanitizer: DomSanitizer, private baseService: BaseService, private activeroute: ActivatedRoute,
        private loader: NgxUiLoaderService) {
    }
    async ngOnInit() {

        setTimeout(() => {

            this.activeroute.queryParams.subscribe(async (params) => {

                if (params.obj) {

                    let data = JSON.parse(params.obj)
                    await this.getreportfile(data)
                }
            })
        }, 0);
    }

    async getreportfile(data) {
        if (data) {
            this.loader.start();
            await this.baseService.postReport('ReportBuilder', 'XportReport', data, 'Report').then((o: any) => {
                this.loader.stop();
                if (o) {

                    var blob = new Blob([o], { type: "application/pdf" });
                    const blobUrl = URL.createObjectURL(blob);
                    this.pdfFile = this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl);

                }
            })
        }
    }
}
