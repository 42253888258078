<!--component html goes here -->
<!--component html goes here -->

<ng-template #lookupModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{caption}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <!-- <form [formGroup]="lookupForm">
            <div class="row" style="margin-bottom: -20px;">
                <div class="col-lg-4">
                    <biz-dropdown caption="Store" [design]="2" [data]="store" formControlName="store"
                        (change)="FillAccHead(1,lookupForm.controls['store'].value)" [valueMember]="'id'"
                        [displayMember]="'name'">
                    </biz-dropdown>
                </div>
                <div class="col-lg-4">
                    <biz-dropdown caption="Accessory Head" [design]="2" [data]="head" formControlName="acchead"
                    (change)="changeHead(lookupForm.controls['acchead'].value)" [valueMember]="'id'" [displayMember]="'name'">
                    </biz-dropdown>
                </div>
            </div>
        </form> -->
        <app-list-page-header [isheading]="false" displayMode="list" [itemOptionsPerPage]="[2, 3, 4, 5, 10, 20, 50, 100]" [showDisplayMode]="false"
            [itemsPerPage]="100" [itemOptionsOrders]="itemOptionsOrders" [showBtnDropDown]="false" [isHeader]="false" [btnSearch]="false"
            (itemsPerPageChange)="itemsPerPageChange($event)" [itemOrder]="itemOrder" [showBtnAdd]="false" [serachAccessories]="true"
            [buttonState]="buttonState" [buttonDisabled]="buttonDisabled" (searchKeyUp)="searchKeyUp($event)"
            (searchByButton)="searchByButton($event)" [showItemsPerPage]="false" ></app-list-page-header>
        <div class="row">
            <div class="col-12 mb-4 data-table-rows actionGrid" style="margin-bottom: 15px;">
                <biz-datatable #table [type]="5" [rows]="rows" [limit]="limit" [columns]="columns"
                    [footerHeight]="50" (onClick)="Click($event)" [selected]="selectedRows" [selectionType]="'checkbox'"
                    (onSelection)='onSelection($event)'>
                </biz-datatable>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="submit" class="btn btn-primary float-right" (click)="save()">Save</button>
    </div>
</ng-template>