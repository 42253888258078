<div class="d-flex flex-row mb-3 pb-3 border-bottom justify-content-between align-items-center">
  <a href="#">
    <img [src]="data.thumb" alt="data.name"
      class="img-thumbnail border-0 rounded-circle list-thumbnail align-self-center xsmall" />
  </a>
  <div class="pl-3 flex-fill">
    <a href="#">
      <p class="font-weight-medium mb-0">{{data.name}}</p>
      <p class="text-muted mb-0 text-small">{{data.status}}</p>
    </a>
  </div>
  <div>
    <a class="btn btn-outline-primary btn-xs" href="#">FOLLOW</a>
  </div>
</div>
