<div class="{{toggleClass}}">
  <!-- <div *ngIf="isOpenSwitcher" class="{{panelToggleClass}}"> -->
  <div class="row">
    <div class="col-lg-12">
      <h3 style="margin-top: 5px;border-bottom: 1px solid #ddd;font-weight:bold;"> <i (click)="panelClose()"
          style="cursor: pointer;font-weight: bold;font-size: 15px;"></i>
        TimeLine</h3>
    </div>
  </div>
  <div class="row mb-1">
    <!-- <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1">
    </div> -->
    <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9" style="padding-right: 0px;">
      <div class="mainSelect">
        <label for="" style="font-weight: bolder;padding-top: 10px;padding-left: 5px;">PPD: </label>
        <ng-select class="selectNG" [items]="CurrData" bindLabel="name" bindValue="id">
        </ng-select>
      </div>
    </div>
    <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2" style="padding-right: 0px;padding-left: 5px;">
      <button type="button" class="btn btn-primary br-5">Submit</button>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="timeContain">
        <ul class="timeline">
          <li>
            <div class="timeline-badge"><i class="glyphicon glyphicon-check"></i></div>
            <div class="timeline-panel">
              <div class="timeline-heading">
                <h4 class="timeline-title">Mussum ipsum cacilds</h4>
                <p><small class="text-muted"><i class="glyphicon glyphicon-time"></i> 11 hours ago via
                    Twitter</small>
                </p>
              </div>
              <div class="timeline-body">
                <p>Mussum ipsum cacilds, vidis litro abertis. Consetis adipiscings elitis. Pra lá , depois
                  divoltis.</p>
              </div>
            </div>
          </li>
          <li class="timeline-inverted">
            <div class="timeline-badge warning"><i class="glyphicon glyphicon-credit-card"></i></div>
            <div class="timeline-panel">
              <div class="timeline-heading">
                <h4 class="timeline-title">Mussum ipsum cacilds</h4>
              </div>
              <div class="timeline-body">
                <p>Mussum ipsum cacilds, vidis litro abertis. Consetis adipiscings elitis. Pra lá , depois
                  divoltis.</p>
              </div>
            </div>
          </li>
          <li>
            <div class="timeline-badge danger"><i class="glyphicon glyphicon-credit-card"></i></div>
            <div class="timeline-panel">
              <div class="timeline-heading">
                <h4 class="timeline-title">Mussum ipsum cacilds</h4>
              </div>
              <div class="timeline-body">
                <p>Mussum ipsum cacilds, vidis litro abertis. Consetis adipiscings elitis. Pra lá , depois
                  divoltis.</p>
              </div>
            </div>
          </li>
          <li class="timeline-inverted">
            <div class="timeline-panel">
              <div class="timeline-heading">
                <h4 class="timeline-title">Mussum ipsum cacilds</h4>
              </div>
              <div class="timeline-body">
                <p>Mussum ipsum cacilds, vidis litro abertis. Consetis adipiscings elitis. Pra lá , depois
                  divoltis.</p>
              </div>
            </div>
          </li>
          <li>
            <div class="timeline-badge info"><i class="glyphicon glyphicon-floppy-disk"></i></div>
            <div class="timeline-panel">
              <div class="timeline-heading">
                <h4 class="timeline-title">Mussum ipsum cacilds</h4>
              </div>
              <div class="timeline-body">
                <p>Mussum ipsum cacilds, vidis litro abertis. Consetis adipiscings elitis. Pra lá , depois
                  divoltis.</p>
              </div>
            </div>
          </li>
          <li>
            <div class="timeline-panel">
              <div class="timeline-heading">
                <h4 class="timeline-title">Mussum ipsum cacilds</h4>
              </div>
              <div class="timeline-body">
                <p>Mussum ipsum cacilds, vidis litro abertis. Consetis adipiscings elitis. Pra lá , depois
                  divoltis.</p>
              </div>
            </div>
          </li>
          <li class="timeline-inverted">
            <div class="timeline-badge success"><i class="glyphicon glyphicon-thumbs-up"></i></div>
            <div class="timeline-panel">
              <div class="timeline-heading">
                <h4 class="timeline-title">Mussum ipsum cacilds</h4>
              </div>
              <div class="timeline-body">
                <p>Mussum ipsum cacilds, vidis litro abertis. Consetis adipiscings elitis. Pra lá , depois
                  divoltis.</p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="row" style="margin-bottom: 15px;">
    <div class="col-12">
      <div class="row" style="padding-left: 10px;padding-right: 10px;">
        <div class="col-2 p-None">
          <span data-toggle="tooltip" title="Save" data-placement="top" class="btn btn-block btn-primary br-5"><i
              class="iconsminds-save"></i>
          </span>
        </div>
        <div class="col-2 p-None">
          <span data-toggle="tooltip" title="Save" data-placement="top" class="btn btn-block btn-primary br-5"><i
              class="iconsminds-save"></i>
          </span>
        </div>
        <div class="col-2 p-None">
          <span data-toggle="tooltip" title="Save" data-placement="top" class="btn btn-block btn-primary br-5"><i
              class="iconsminds-save"></i>
          </span>
        </div>
        <div class="col-2 p-None">
          <span data-toggle="tooltip" title="Save" data-placement="top" class="btn btn-block btn-primary br-5"><i
              class="iconsminds-save"></i>
          </span>
        </div>
        <div class="col-2 p-None">
          <span data-toggle="tooltip" title="Save" data-placement="top" class="btn btn-block btn-primary br-5"><i
              class="iconsminds-save"></i>
          </span>
        </div>
        <div class="col-2 p-None">
          <span data-toggle="tooltip" title="Save" data-placement="top" class="btn btn-block btn-primary br-5"><i
              class="iconsminds-save"></i>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <biz-editor class="timeline-comment" caption="Comment" [height]="100"></biz-editor>
    </div>
    <!-- <div class="col-2 text-center" style="padding-left: 0px;">
      <button type="button" style="border-radius: 5px;height: 100px;font-size: 18px;" class="btn btn-primary">Send</button>
    </div> -->
  </div>


  <!-- </div> -->

  <a (click)="toggleSwitcher($event)" data-toggle="tooltip" title="TimeLine" data-placement="top"
    class="c-pointer timeline-button"> <i class="iconsminds-newsvine"></i> </a>
</div>

<style>
  /* Panel */
  .Panel {
    width: 500px !important;
    position: fixed;
    z-index: 1031;
    top: 50%;
    right: 0;
    transform: translate(280px, -50%);
    padding: 10px;
    padding-bottom: 10px;
    background: #fff;
    height: 100%;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
      0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  }

  .shown {
    transform: translate(0, calc(-50% + 0.5px));
    transition: transform 0.4s ease-out;
  }

  .hidden {
    transform: translate(280px, -50%);
    transition: transform 0.4s ease-out;
  }

  .isRight {
    right: -16.2% !important;
    transform: translate(280px, -50%);
    transition: transform 0.4s ease-out;
  }

  .notRight {
    right: 0 !important;
  }

  .p-None {
    padding-left: 1px;
    padding-right: 1px;
  }

  .br-5 {
    border-radius: 5px;
  }

  .mainSelect {
    display: flex;
    border: 1px solid #ddd;
    border-radius: 5px;
  }

  .selectNG {
    width: 100%;
  }
</style>