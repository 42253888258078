// Angular Imports
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// This Module's Components
import { BizInputListerComponent } from './biz-input-lister.component';
import { CommonModule } from '@angular/common';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { BizDropdownModule } from '../biz-dropdown/biz-dropdown.module';
import { BizSelectModule } from '../biz-select/biz-select.module';

@NgModule({
    imports: [
        FormsModule, ReactiveFormsModule, CommonModule, SimpleNotificationsModule.forRoot(), BizDropdownModule, BizSelectModule
    ],
    declarations: [
        BizInputListerComponent,
    ],
    exports: [
        BizInputListerComponent,
    ]
})
export class BizInputListerModule {

}
