<div *ngIf="type === 1" [class]="'card ' + class">
  <div *ngIf="isSortable" class="card-header p-0 position-relative">
    <div class="position-absolute handle card-icon">
      <i class="simple-icon-shuffle"></i>
    </div>
  </div>
  <div class="card-body d-flex justify-content-between align-items-center">
    <h5 class="card-title mb-0">{{title}}</h5>
    <div class="progress-bar-circle position-relative">
      <div class="progressbar-text">{{ percent }}%</div>
      <round-progress [current]="percent" [max]="100" [responsive]="true" [duration]="800"
        [animation]="'easeInOutQuart'" [animationDelay]="0" [radius]="50" [rounded]="true" [stroke]="4">
      </round-progress>
    </div>
  </div>
</div>


<round-progress *ngIf="type === 2" [current]="percent" [max]="100" [responsive]="true" [duration]="800"
  [animation]="'easeInOutQuart'" [animationDelay]="0" [radius]="50" [rounded]="true" [stroke]="4">
</round-progress>