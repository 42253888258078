import { Component, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatatableComponent, id } from '@swimlane/ngx-datatable';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BaseComponent } from '../../@core/BaseComponent/base.component';
//import { BaseComponent } from '../../@core/BaseComponent/base.component';
import { BizDatatableComponent } from '../../@core/components/biz-datatable/biz-datatable.component';
import { Enum } from '../../@core/components/config';
//import {NgbAccordion} from 'ngx-bootstrap/accordion';
@Component({  
    selector: 'exp-inv-approval',
    templateUrl: 'exp-inv-approval.component.html',  
})
export class ExpInvApprovalComponent extends BaseComponent {
    headerMenu: any[] = [];
    requestMenu: any[] = [];
    ppdParams: any[] = [];
    GadgetsTiles: any[] = [];
    selectHeader: any = { id: 1, css_Class: 'iconsminds-file-clipboard-file---text', menuName: 'Detail' };
    selectRequest: any = { id: 11, css_Class: 'iconsminds-file-clipboard-file---text', menuName: 'Leather Inquiry' };
    data: any = {};
    customButtonList: any[] = [];
    columnDef: any[] = [];
    columnDefIP: any[] = [];
    detailList: any[] = [];
    accessoriesList: any[] = [];


    selectedConversation: any = { ppdid: 0, message: [] };
    @ViewChild('contrastModal') contrastModal: TemplateRef<any>;
    @ViewChild('sizeModal') sizeModal: TemplateRef<any>;

    @ViewChild('detailModal') detailModal: TemplateRef<any>;
    @ViewChild('dtltable') dtltable: BizDatatableComponent;
    message: string;
    gridColumn: any[] = [];
    gridData: any[] = [];
    isReview: boolean = false
    isAuthoized: boolean = false
  
    @ViewChild('accTable') mainTable: DatatableComponent;

    
    contrastmodalRef: any;
    sizemodalRef: any;
    modalRef: any;
    constructor(private notification: NotificationsService, private modalService: BsModalService, private activeroute: ActivatedRoute, private newTabRoute: Router) {
        super()
    }
    async initializeForm() {
        this.myForm = this.formBuilder.group({
            'id': [''],
            'Dated': [''],
            'department': [''],            
        })
    }

    async ngOnInit() {

        this.customButtonList = [{ icon: 'iconsminds-line-chart-3', prop: 'purchase', name: 'purchase' }, { name: 'Issueing', icon: 'simple-icon-eye', prop: 'Issueing' }, { name: 'suppliers', icon: 'iconsminds-duplicate-layer', prop: 'suppliers' }];
        await this.initializeForm();       
        this.detailConfig();
       
        debugger
        setTimeout(() => {
            debugger
            this.activeroute.queryParams.subscribe(async (params) => {
                debugger
                if (params.id) {
                    debugger
                    let id = JSON.parse(params.id)
                    this.myForm.controls['id'].setValue(Number(id))
                    await this.onEnter(Number(id))
                }
            })
        }, 0);

       


      //  this.ppdParams = [{ name: 'filed', value: 'SpecialSearch' }, { name: 'value', value: 'value' }, { name: 'UserID', value: localStorage.getItem('UserID') }, { name: 'LocationID', value: localStorage.getItem('LocationID') }]


    }

   
    async detailConfig() {
        this.columnDef = [

          
            {
                name: 'PPD #',
                prop: 'fkPPdid',
                width: 100,
                type: Enum.Datatable_ControlType.Text,
                editable: false
            },
            
            {
                name: 'Style',
                prop: 'style',
                width: 200,
                type: Enum.Datatable_ControlType.Text,
                editable: false
            },
            {
                name: 'Leather',
                prop: 'custLth',
                width: 250,
                type: Enum.Datatable_ControlType.Text,
                editable: false
            },    
            {
                name: 'Color',
                prop: 'custColor',
                width: 250,
                type: Enum.Datatable_ControlType.Text,
                editable: false
            },
            {
                name: 'Order',
                prop: 'orderpcs',
                width: 100,
                type: Enum.Datatable_ControlType.Text,
                editable: false
            },
            {
                name: 'Ship Pcs',
                prop: 'shippcs',
                width: 100,
                type: Enum.Datatable_ControlType.Text,
                editable: false
            },            
            {
                name: 'Price',
                prop: 'rate',
                width: 100,
                type: Enum.Datatable_ControlType.Text,
                editable: false
            },
            {
                name: 'Del-Date',
                prop: 'delDate',
                width: 100,
                type: Enum.Datatable_ControlType.Text,
                editable: false
            },


        ];
    }

    async savingDtl(id){

    }
    async getDate() {
        debugger
        if (this.myForm.controls['id'].value > 0) {
            await this.onEnter(this.myForm.controls['id'].value)
        }
    }
    async onEnter(id) {
        debugger
        if (id > 0) {
            debugger
            this.getExportDetail(id);
        }
    }


  
    async getExportDetail(id) {
        debugger
        let val = 'tType=0' + '&id=' + id;
        debugger
        await this.get('ExportIndex', 'getDeatil', val, 'apiURL').then((i: any) => {
            debugger
            if (i && i.length && i.length > 0) {
                this.data = i[0];
                this.detailList = i;
            } else {
                this.detailList = [];                
            }
        })
    };

    
    errorLog: any[] = [];
    async AuthoizedSave() {
        debugger
        if (this.myForm.controls['id'].value > 0 ) {

            let data = 'id=' + this.myForm.controls['id'].value + '&AuthoizeBy=' + localStorage.getItem('UserID');
            debugger
            await this.get('ExportIndex', 'ExpAuthorized', data, 'apiURL').then(async (o) => {
                debugger
                if (o === "record successfully updated") {
                    this.notification.create('Success', o,
                        NotificationType.Error, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false });
                    await this.onEnter(1);

                }
                else if (o && o.message) {

                    this.notification.create('Error', o.message,
                        NotificationType.Error, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false });
                } else {
                    this.notification.create('Success', o,
                        NotificationType.Error, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false });
                }
            }).catch(e => {

                this.notification.create('Error', e.message,
                    NotificationType.Error, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false });
            })
        } else {
            this.notification.create('Error', "PPD Not Found!",
                NotificationType.Error, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false });
        }
        
    }

 

    toggleExpandGroup(group) {
        this.mainTable.groupHeader.toggleExpandGroup(group);
    }
   
   
   
   
   
   


    async setSelectRequest(item) {
        this.selectRequest = item;
    }
    getSum(group) {
        let sum = 0;
        if (group && group.value && group.value.length > 0) {
            group.value.map(y => {
                sum += Number(y.qty)
            })
        }
        return sum;
    }
    async cardClick(e) {
  
        // if (e && e.heading) {
    
        //   this.ModalName = e.heading;
        //   await this.getGadgetDetail(e.heading);
        // }
      }
  
   
    async Action(e) {
        debugger        
       
    }

  

}

