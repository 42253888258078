import { AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Input, Output, ViewChild, OnInit } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { ControlBase } from '../config';

@Component({
    selector: 'biz-datalist',
    templateUrl: 'biz-datalist.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => BizDatalistComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => BizDatalistComponent),
            multi: true,
        },
        {
            provide: ControlBase,
            useExisting: BizDatalistComponent,
        },
    ],
})
export class BizDatalistComponent extends ControlBase
    implements ControlValueAccessor, Validator, AfterViewInit, OnInit {
    @Output() initialized: EventEmitter<BizDatalistComponent> = new EventEmitter<BizDatalistComponent>();
    constructor(private element: ElementRef) {
        super()
    }

    ngAfterViewInit() {
        if (this.design === "3") {

        }
        setTimeout(() => {

            this.initialized.emit(this);
        }, 0);
    }

    async ngOnInit() {
       
    }

    private onChange = (value: any) => { };
    private onTouched = () => { };
    // onChange = (value: any) => { };
    // onTouched = () => { };
    innerValue: any;
    @Input()
    type: number = 1;

    @Input()
    design: any = 1;

    @Input()
    config: any[] = [];

    @Input()
    valueMember: string = "";

    @Input()
    displayMember: string = "";

    @Input()
    caption: string = "";

    @Input()
    elementid: string = "browsers";
    @Input()
    elementName: string = "browsers";

    @Input()
    fieldName: string = "";

    @Input()
    disabled: boolean = false;

    @Input()
    listerColumns: any[] = [];

    selectedRows: any;
    _width: any;
    @Input()
    get width(): any {

        return this._width;
    }
    set width(v: any) {

        if (v) {

            this._width = v - 45 + 'px'
        }
    }

    @Input()
    get value(): any {
        return this.innerValue;
    }

    set value(v: any) {

        if (v && v !== this.innerValue) {

            this.innerValue = v;
            this.onChange(v);
        }
    }

    controlEl: any
    errMsg: any;
    dirtytext: boolean = false;
    isInvalid: boolean = false;

    _data: any[] = [];
    get data(): any[] {
        return this._data;
    }

    @Input()
    set data(value: any[]) {

        this._data = value;
    }

    validate(control: AbstractControl): ValidationErrors | null {

        this.dirtytext = false
        this.controlEl = control

        // if ((this.innerValue === null) && this.dcsTextBox && control.errors && control.errors.required ) {

        if ((this.innerValue === null || this.innerValue === "" || this.innerValue === undefined) && control.errors && control.errors.required) {
            this.errMsg = "error"

            this.isInvalid = true
            return { invalid: true }
        }
        else {

            this.errMsg = null
            this.isInvalid = false
        }
        return null;
    }

    getErrorDescrption(caption: any) {

    }

    writeValue(value: any) {

        if (value !== this.innerValue) {

            this.innerValue = value;
        }
    }

    async Modelchange() {

        var val = Number(this.value);
        if (!isNaN(val)) this.value = val;
        //this.value = Number(this.value);
    }

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) {

        this.onTouched = fn;
    }

    onBlur() {
        var requiredField;

        if (
            this.controlEl &&
            this.controlEl.errors &&
            this.controlEl.errors.required
        ) {
            requiredField = this.controlEl.errors.required;
        }
        if (
            (this.innerValue === null ||
                this.innerValue === "" ||
                this.innerValue === undefined) &&
            requiredField
        ) {
            // console.log(this.innerValue.matchAll(this.inputMask))

            this.errMsg = "";
            this.dirtytext = true;
        } else {
            this.errMsg = null;
            this.dirtytext = false;
        }
        this.onTouched();
    }

    ondelete(event: any) {
        this.value = undefined;
    }

}
