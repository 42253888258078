<div class="card mb-4">
  <div class="position-absolute card-top-buttons">
      <button class="btn btn-header-light icon-button">
          <i class="simple-icon-refresh"></i>
      </button>
  </div>
  <div class="card-body">
      <div class="mb-3">
          <div class="post-icon mr-3 d-inline-block"><a href="#"><i
                      class="simple-icon-heart mr-1"></i></a> <span>4
                  Likes</span></div>
          <div class="post-icon d-inline-block"><i class="simple-icon-bubble mr-1"></i> <span>1
                  Comment</span></div>
      </div>
      <p class="mb-3">
          Vivamus ultricies augue vitae commodo condimentum. Nullam faucibus eros eu mauris
          feugiat, eget consectetur tortor tempus.
          <br /><br />
          Sed volutpat mollis dui eget fringilla.
          Vestibulum blandit urna ut tellus lobortis tristique. Vestibulum ante ipsum primis in
          faucibus orci luctus et ultrices posuere cubilia Curae; Pellentesque quis cursus
          mauris.
          <br /><br />
          Nulla non purus fermentum, pulvinar dui condimentum, malesuada nibh. Sed
          viverra quam urna, at condimentum ante viverra non. Mauris posuere erat sapien, a
          convallis libero lobortis sit amet. Suspendisse in orci tellus.
      </p>
      <p class="text-muted text-small mb-2">Tags</p>
      <p class="mb-3">
        <span class="badge mb-1 mr-1 badge-outline-secondary badge-pill">{{'Flour' | uppercase}}</span>
        <span class="badge mb-1 mr-1 badge-outline-secondary badge-pill">{{'Chocolate' | uppercase}}</span>
        <span class="badge mb-1 mr-1 badge-outline-secondary badge-pill">{{'Caster Sugar' | uppercase}}</span>
        <span class="badge mb-1 mr-1 badge-outline-secondary badge-pill">{{'Baking Powder' | uppercase}}</span>
      </p>
  </div>
</div>
