import { Component, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatatableComponent, id } from '@swimlane/ngx-datatable';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BaseComponent } from '../../@core/BaseComponent/base.component';
//import { BaseComponent } from '../../@core/BaseComponent/base.component';
import { BizDatatableComponent } from '../../@core/components/biz-datatable/biz-datatable.component';
import { Enum } from '../../@core/components/config';
//import {NgbAccordion} from 'ngx-bootstrap/accordion';
@Component({  
    selector: 'purchase-order-approval',
    templateUrl: 'purchase-order-approval.component.html',
  
})
export class PurchaseOrderApprovalComponent extends BaseComponent {
    headerMenu: any[] = [];
    requestMenu: any[] = [];
    ppdParams: any[] = [];
    GadgetsTiles: any[] = [];
    selectHeader: any = { id: 1, css_Class: 'iconsminds-file-clipboard-file---text', menuName: 'Detail' };
    selectRequest: any = { id: 11, css_Class: 'iconsminds-file-clipboard-file---text', menuName: 'Leather Inquiry' };
    data: any = {};
    customButtonList: any[] = [];
    columnDef: any[] = [];
    columnDefIP: any[] = [];
    detailList: any[] = [];
    accessoriesList: any[] = [];


    selectedConversation: any = { ppdid: 0, message: [] };
    @ViewChild('contrastModal') contrastModal: TemplateRef<any>;
    @ViewChild('sizeModal') sizeModal: TemplateRef<any>;

    @ViewChild('detailModal') detailModal: TemplateRef<any>;
    @ViewChild('dtltable') dtltable: BizDatatableComponent;
    message: string;
    gridColumn: any[] = [];
    gridData: any[] = [];
    isReview: boolean = false
    isAuthoized: boolean = false
  
    @ViewChild('accTable') mainTable: DatatableComponent;

    
    contrastmodalRef: any;
    sizemodalRef: any;
    modalRef: any;
    constructor(private notification: NotificationsService, private modalService: BsModalService, private activeroute: ActivatedRoute, private newTabRoute: Router) {
        super()
    }
    async initializeForm() {
        this.myForm = this.formBuilder.group({
            'id': [''],
            'Dated': [''],
            'department': [''],            
        })
    }

    async ngOnInit() {

        this.customButtonList = [{ icon: 'iconsminds-line-chart-3', prop: 'purchase', name: 'purchase' }, { name: 'Issueing', icon: 'simple-icon-eye', prop: 'Issueing' }, { name: 'suppliers', icon: 'iconsminds-duplicate-layer', prop: 'suppliers' }];
        await this.initializeForm();
        await this.getitemTiles(0);
        this.detailConfig();
       
        debugger
        setTimeout(() => {
            debugger
            this.activeroute.queryParams.subscribe(async (params) => {
                debugger
                if (params.id) {
                    debugger
                    let id = JSON.parse(params.id)
                    this.myForm.controls['id'].setValue(Number(id))
                    await this.onEnter(Number(id))
                }
            })
        }, 0);

       


      //  this.ppdParams = [{ name: 'filed', value: 'SpecialSearch' }, { name: 'value', value: 'value' }, { name: 'UserID', value: localStorage.getItem('UserID') }, { name: 'LocationID', value: localStorage.getItem('LocationID') }]


    }

   
    async detailConfig() {
        this.columnDef = [

          
            {
                name: 'ID #',
                prop: 'id',
                width: 100,
                type: Enum.Datatable_ControlType.Text,
                editable: false
            },
            
            {
                name: 'Head',
                prop: 'headName',
                width: 100,
                type: Enum.Datatable_ControlType.Text,
                editable: false
            },
            {
                name: 'Accessories Name',
                prop: 'name',
                width: 450,
                type: Enum.Datatable_ControlType.Text,
                editable: false
            },    
            // {
            //     name: 'Unit',
            //     prop: 'unitName',
            //     width: 80,
            //     type: Enum.Datatable_ControlType.Text,
            //     editable: false
            // },
            {
                name: 'Stock',
                prop: 'rtStock',
                width: 100,
                type: Enum.Datatable_ControlType.Text,
                editable: false
            },
            {
                name: 'Required',
                prop: 'qtyRequired',
                width: 120,
                type: Enum.Datatable_ControlType.Text,
                editable: false
            },
            {
                name: 'IndentQty',
                prop: 'indentQty',
                width: 120,
                type: Enum.Datatable_ControlType.Text,
                editable: false
            },            
            {
                name: 'Price',
                prop: 'expectedRate',
                width: 100,
                type: Enum.Datatable_ControlType.Text,
                editable: false
            },
            {
                name: 'Usd',
                prop: 'usdRate',
                width: 100,
                type: Enum.Datatable_ControlType.Text,
                editable: false
            },
            {
                name: 'Del-Date',
                prop: 'expDelDate',
                width: 100,
                type: Enum.Datatable_ControlType.Text,
                editable: false
            },
            
            {
                name: 'LPQty',
                prop: 'lpQty',
                width: 100,
                type: Enum.Datatable_ControlType.Text,
                editable: false
            },
            {
                name: 'LPRate',
                prop: 'lpRate',
                width: 100,
                type: Enum.Datatable_ControlType.Text,
                editable: false
            },
            {
                name: 'LPDate',
                prop: 'lpDate',
                width: 100,
                type: Enum.Datatable_ControlType.Text,
                editable: false
            },
            {
                name: 'LPSupplier',
                prop: 'supplier',
                width: 250,
                type: Enum.Datatable_ControlType.Text,
                editable: false
            },
            {
                name: '90Day pur',
                prop: 'totalRcvd',
                width: 100,
                type: Enum.Datatable_ControlType.Text,
                editable: false
            },
            {
                name: '90Day Issued',
                prop: 'totalIssued',
                width: 100,
                type: Enum.Datatable_ControlType.Text,
                editable: false
            },
            {
                name: 'Remarks',
                prop: 'storeRemarks',
                width: 175,
                type: Enum.Datatable_ControlType.Text,
                editable: false
            },            
            {
                name: 'PPD',
                prop: 'fkppdid',
                width: 100,
                type: Enum.Datatable_ControlType.Text,
                editable: false
            },
            {
                name: 'Leather',
                prop: 'lthrname',
                width: 275,
                type: Enum.Datatable_ControlType.Text,
                editable: false
            },

        ];
    }

    async savingDtl(id){

    }
    async getDate() {
        debugger
        if (this.myForm.controls['id'].value > 0) {
            await this.onEnter(this.myForm.controls['id'].value)
        }
    }
    async onEnter(id) {
        debugger
        if (id > 0) {
            debugger
            this.getAccessoriesDetail(id);
        }
    }


  
    async getAccessoriesDetail(id) {
        debugger
        let val = 'tType=0' + '&id=' + id;
        debugger
        await this.get('PurchaseOrder', 'getDeatil', val, 'Accessories').then((i: any) => {
            debugger
            if (i && i.length && i.length > 0) {
                this.data = i[0];
                this.detailList = i;
            } else {
                this.detailList = [];                
            }
        })
    };

    
    errorLog: any[] = [];
    async AuthoizedSave() {
        debugger
        if (this.myForm.controls['id'].value > 0 ) {

            let data = 'id=' + this.myForm.controls['id'].value + '&AuthoizeBy=' + localStorage.getItem('UserID');
            debugger
            await this.get('PurchaseOrder', 'POAuthorized', data, 'Accessories').then(async (o) => {
                debugger
                if (o === "record successfully updated") {
                    this.notification.create('Success', o,
                        NotificationType.Error, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false });
                    await this.onEnter(1);

                }
                else if (o && o.message) {

                    this.notification.create('Error', o.message,
                        NotificationType.Error, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false });
                } else {
                    this.notification.create('Success', o,
                        NotificationType.Error, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false });
                }
            }).catch(e => {

                this.notification.create('Error', e.message,
                    NotificationType.Error, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false });
            })
        } else {
            this.notification.create('Error', "PPD Not Found!",
                NotificationType.Error, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false });
        }
        
    }

    async saveAA() {
        debugger
        if (this.myForm.controls['id'].value > 0 && this.isAuthoized) {

            let data = 'PPDID=' + this.myForm.controls['id'].value + '&AuthoizeBy=' + localStorage.getItem('UserID');

            await this.get('SalesAccRequisition', 'Authorized', data, 'Accessories').then(async (o) => {

                if (o && o.message === "record successfully updated") {
                    await this.onEnter(this.myForm.controls['id'].value);

                }
                else if (o && o.message) {

                    this.notification.create('Error', o.message,
                        NotificationType.Error, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false });
                } else {
                    this.notification.create('Error', o,
                        NotificationType.Error, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false });
                }
            }).catch(e => {

                this.notification.create('Error', e.message,
                    NotificationType.Error, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false });
            })
        } else {
            this.notification.create('Error', "PPD Not Found!",
                NotificationType.Error, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false });
        }
    }


    toggleExpandGroup(group) {
        this.mainTable.groupHeader.toggleExpandGroup(group);
    }
   
    async onCustomButtonClick(e) {
        debugger
        if (e.item && e.item.prop === "purchase") {
            let val = e.row;
            let val2 = e.item.prop;
            this.gridData = [];
            this.modalRef = this.modalService.show(this.detailModal, {
                animated: true,
                backdrop: 'static',
                class: 'modal-xl',
            });
            await this.getpurchasedDetail(val.fkAccItemMasterID,val2);
       
           
        }

        if (e.item && e.item.prop === "Issueing") {
            let val = e.row;
            let val2 = e.item.prop;
            this.gridData = [];
            this.modalRef = this.modalService.show(this.detailModal, {
                animated: true,
                backdrop: 'static',
                class: 'modal-xl',
            });
            await this.getpurchasedDetail(val.fkAccItemMasterID,val2);
       
           
        }
        if (e.item && e.item.prop === "suppliers") {
            let val = e.row;
            let val2 = e.item.prop;
            this.gridData = [];
            this.modalRef = this.modalService.show(this.detailModal, {
                animated: true,
                backdrop: 'static',
                class: 'modal-xl',
            });
            await this.getpurchasedDetail(val.fkAccItemMasterID,val2);
       
           
        }

     
    }
    async getpurchasedDetail(itemId,heading) {
        debugger
        let val = '&itemid=' + itemId + '&heading=' + heading;
        await this.get('SalesAccRequisition', 'getitemsDetai', val, 'Accessories').then(i => {
            debugger
            if (i && i.length && i.length > 0) {
                let keys: any[] = [];
                let object = i[0];
                Object.keys(object).forEach((e: any) => {
                    if (e !== "formName") {

                        let obj = {
                            name: e.toUpperCase(),
                            prop: e,
                        }

                        keys.push(obj);
                    }
                })
                debugger
                this.gridColumn = [...keys];
                debugger
                this.gridData = i
            }
            else{

                this.gridData= [];
            }
        })
    }
   
   
   


    async setSelectRequest(item) {
        this.selectRequest = item;
    }
    getSum(group) {
        let sum = 0;
        if (group && group.value && group.value.length > 0) {
            group.value.map(y => {
                sum += Number(y.qty)
            })
        }
        return sum;
    }
    async cardClick(e) {
  
        // if (e && e.heading) {
    
        //   this.ModalName = e.heading;
        //   await this.getGadgetDetail(e.heading);
        // }
      }
  
    async getitemTiles(id) {
        debugger
        let val = 'id=' + id;
        debugger
        this.loader.startBackground();
        await this.get('SalesAccRequisition', 'getRequestItemsTiles', val, 'Accessories').then(i => {
          debugger
          this.loader.stopBackground();
          if (i && i.length && i.length > 0) {
            this.GadgetsTiles = i;
            console.log(i);
          } else {
            this.GadgetsTiles = [];
    
          }
        })
    }
    async Action(e) {
        debugger        
        this.getitemTiles(e.row.fkAccItemMasterID);
    }

  

}
