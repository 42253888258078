import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-detail-info',
  templateUrl: './product-detail-info.component.html'
})
export class ProductDetailInfoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
