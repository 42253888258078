// Angular Imports
import { NgModule } from '@angular/core';

// This Module's Components
import { BizInputComponent } from './biz-input.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [
        FormsModule,ReactiveFormsModule,CommonModule
    ],
    declarations: [
        BizInputComponent,
    ],
    exports: [
        BizInputComponent,
    ]
})
export class BizInputModule {

}
