import { Component, Input, ViewChild, OnInit, HostListener, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { Enum } from '../@core/components/config';
import { BaseComponent } from '../@core/BaseComponent/base.component';
import { ExcelService } from '../@core/Services/export.service';

@Component({
    selector: 'report-grid-viewer',
    templateUrl: 'report-grid-viewer.component.html',
})
export class ReportGridViewerComponent extends BaseComponent implements OnInit {
    @Input() _columns: any[] = [];
    @Input() _rows: any[] = [];
    @Input() header: any[] = [];
    @Input() limit: number = 10;
    @Input() headerHeight: number = 30;
    @ViewChild('table') mainTable: DatatableComponent;
    @ViewChild('tableGroup') tableGroup: DatatableComponent;
    @ViewChild('table') native: ElementRef;
    @Input() columnMode: string = Enum.ColumnMode.force;
    groupRowsBy: any;
    companyName: string;
    groupRowsByCaption: string;
    _height = '400px'
    groupExpansionDefault: boolean = false;
    reportSP: any[] = [];
    reportParameters: any[] = [];
    reportConnection: any;
    classGrid = 'col-lg-12 md-4 data-table-rows actionGrid rowColor nonegrouping';
    

    constructor(private activeroute: ActivatedRoute, private _ExcelService: ExcelService) {
        super();
    }

    async ngOnInit() {
        window.addEventListener('keydown', (event) => {
            if (event.keyCode === 80 && (event.ctrlKey || event.metaKey) && !event.altKey) {
                event.preventDefault();
                if (event.stopImmediatePropagation) {
                    event.stopImmediatePropagation();
                } else {
                    event.stopPropagation();
                }
                return;
            }
        }, true);

        this.companyName = localStorage.getItem('SegmentName')

        setTimeout(async () => {

            let data = JSON.parse(localStorage.getItem('gridData'));
            this.header = data.header;
            this.reportSP = data.reportSP;
            this.reportConnection = data.reportConnection;

            if (data.reportParameters.length > 0)
                data.reportParameters.map(u => {

                    u.value = data.header.length > 0 ? data.header.filter(o => o.ObjectName === u.ObjectName)[0].value : 0;
                })
            this.reportParameters = data.reportParameters;

            let obj = {
                ReportID: this.reportSP[0].ReportID,
                SP_Name: this.reportSP[0].SP_Name,
                ReportServer: this.reportConnection.ReportServer,
                ReportDatabase: this.reportConnection.ReportDatabase,
                ReportDbUserId: this.reportConnection.ReportDbUserId,
                ReportDbPassword: this.reportConnection.ReportDbPassword,
                paramters: this.reportParameters
            }

            await this.getReportData(obj);
        }, 0);
    }
    tempData: any[] = [];

    async onSearchKeyUp(e) {
        debugger
        if (e && e.target.value) {
            debugger
            const val = e.target.value;
            debugger
            if (val === "" && this.tempData.length > 0) {

                this._rows = [...this.tempData]
                this.tempData = [];
            }
            const count = this._columns.length;
            const keys = Object.keys(this._rows[0]);

            if (this.tempData.length === 0) this.tempData = this._rows;

            this._rows = this._rows.filter(item => {

                for (let i = 0; i < count; i++) {
                    if (
                        (item[keys[i]] &&
                            item[keys[i]]
                                .toString()
                                .toLowerCase()
                                .indexOf(val) !== -1) ||
                        !val
                    ) {
                        return true;
                    }
                }
            })
        } else {
            this._rows = [...this.tempData]
        }
    }
    
    


    toggleExpandGroup(group) {
        
        this.tableGroup.groupHeader.toggleExpandGroup(group);
    }

    async cellClickGroupBy(e, prop, name) {
        
        this.classGrid = 'col-lg-12 md-4 data-table-rows actionGrid rowColor grouping';
        this.groupRowsByCaption = name;
        this.groupRowsBy = prop;
        
        await this.setGridContext(this._rows);
        setTimeout(() => {
            this.groupExpansionDefault = true;
        }, 0);
    }

    async printOpen() {

        let table = document.getElementById('table');
        if (table) {
            table.style.height = '100%'
        }
        let body: any = document.getElementsByTagName('datatable-body')

        if (body) {

            body[0].style.height = '100%'
        }
        window.print();
        if (table) {
            table.style.height = '400px'
        }
        if (body) {

            body[0].style.height = '370px'
        }
    }

    export() {

        this._ExcelService.exportTableToCSV("export")
    }

    @HostListener('window:keyup', ['$event']) onKeyDown(e) {

        if (e.ctrlKey && e.keyCode == 80) {

            this.printOpen();
        }
    }

    async refresh() {
        this.groupRowsBy = undefined;
        this.groupRowsByCaption = undefined;
        this.classGrid = 'col-lg-12 md-4 data-table-rows actionGrid rowColor nonegrouping';
        await this.setGridContext(this._rows);
        this.groupExpansionDefault = false;
    }

    async setGridContext(data) {
        
        if (data.length > 0) {
            
            let keys: any[] = [];
            let object = data[0];

            Object.keys(object).forEach((e: any) => {
                let obj = {
                    name: e.toUpperCase(),
                    prop: e,
                }
                keys.push(obj);
            })
            this._columns = [...keys];
            
            this._rows = [...data];
        } else {
            
            this._rows = [];
        }
    }

    async getReportData(data) {

        if (data) {

            await this.postMethod('ReportDetail', 'getReportData', data, 'Report').then(i => {

                if (i && i.length && i.length > 0) {
                    this.setGridContext(i);
                }
            })
        }
    }


}
