import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-detail-info-alt',
  templateUrl: './product-detail-info-alt.component.html'
})
export class ProductDetailInfoAltComponent implements OnInit {
  rate = 4;
  constructor() { }

  ngOnInit(): void {
  }

}
