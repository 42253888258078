// Angular Imports
import { NgModule } from '@angular/core';
import { QuillModule } from 'ngx-quill';

// This Module's Components
import { BizEditorComponent } from './biz-editor.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [
        QuillModule.forRoot(), FormsModule, ReactiveFormsModule, CommonModule
    ],
    declarations: [
        BizEditorComponent,
    ],
    exports: [
        BizEditorComponent,
    ]
})
export class BizEditorModule {

}
