// Angular Imports
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BizDatatableModule } from '../biz-datatable/biz-datatable.module';

// This Module's Components
import { LeatherLookupComponent } from './leather-lookup.component';
import { PagesContainersModule } from '../../../../../containers/pages/pages.containers.module';
import { BizSelectModule } from '../biz-select/biz-select.module';

@NgModule({
    imports: [
        FormsModule, ReactiveFormsModule, CommonModule, BizDatatableModule, PagesContainersModule, BizSelectModule
    ],
    declarations: [
        LeatherLookupComponent,
    ],
    exports: [
        LeatherLookupComponent,
    ]
})
export class LeatherLookupModule {

}
