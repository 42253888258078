import { Component, OnInit, Renderer2, HostListener } from '@angular/core';
import { getThemeColor, setThemeColor, setThemeRadius, getThemeRadius } from '../../../../app/utils/util';

@Component({
  selector: 'app-timeline-panel',
  templateUrl: './timeline-panel.component.html',
})
export class TimeLinePanelComponent implements OnInit {

  panelToggleClass: any = 'Panel';
  isOpenSwitcher: boolean;
  _right: any = '16.2% !important';

  colors: string[] = [
    'bluenavy',
    'blueyale',
    'blueolympic',
    'greenmoss',
    'greenlime',
    'purplemonster',
    'orangecarrot',
    'redruby',
    'yellowgranola',
    'greysteel',
  ];
  selectedColor: string = getThemeColor();
  toggleClass = 'Panel isRight';
  radius = getThemeRadius();
  CurrData: any = [];
  constructor(private renderer: Renderer2) {
    this.changeRadius(this.radius);
  }
  ngOnInit(): void {
    this.CurrData = [
      {
        name: 'Features',
        prop: 'id',
      },
      {
        name: 'Updates',
        prop: 'id',
      },
    ]
  }

  changeColor(color: string): void {
    this.selectedColor = color;
    setThemeColor(color);
    setTimeout(() => {
      window.location.reload();
    }, 500);
  }

  toggleSwitcher(event): void {
    this.isOpenSwitcher = !this.isOpenSwitcher;
    this.toggleClass = this.isOpenSwitcher
      ? 'Panel notRight shown'
      : 'Panel isRight hidden';
    event.stopPropagation();
    if (this.isOpenSwitcher === true) {
      this._right = '0% !important';
    } else {
      this._right = '16.2% !important';
    }
  }

  changeRadius(radius): void {
    if (radius === 'flat') {
      this.renderer.removeClass(document.body, 'rounded');
    } else {
      this.renderer.addClass(document.body, 'rounded');
    }
    setThemeRadius(radius);
  }

  @HostListener('document:click', ['$event'])
  handleDocumentClick(event): void {
    // this.isOpenSwitcher = false;
    // this.toggleClass = 'theme-colors hidden';
  }

  @HostListener("document:click", ["$event"]) clickedOutside(event: any) {
    // this.isOpenSwitcher = false;
    // this.toggleClass = 'Panel isRight';
  }

  panelClose() {
    this.isOpenSwitcher = !this.isOpenSwitcher;
    this.panelToggleClass = this.isOpenSwitcher
      ? 'Panel shown'
      : 'Panel hidden';

  }
}
