import { Component, EventEmitter, Input, Output, ViewChild, OnInit, AfterViewInit, Query, ViewContainerRef } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { BaseService } from '../../Services/base.service';
import { ServiceLocator } from '../../Services/locator.service';
import { Enum, MapConfiguration } from '../config';
import { BizDropdownComponent } from '../biz-dropdown/biz-dropdown.component';
import { PageChangedEvent, PaginationComponent } from 'ngx-bootstrap/pagination';

@Component({
    selector: 'biz-datatable',
    templateUrl: 'biz-datatable.component.html',
})
export class BizDatatableComponent implements OnInit, AfterViewInit {
    customRow: any = {};
    buttonState = '';
    buttonDisabled = false;
    @Output() initialized: EventEmitter<BizDatatableComponent> = new EventEmitter<BizDatatableComponent>();
    private baseService: any;
    constructor() {
        this.baseService = ServiceLocator.injector.get(BaseService);

    }
    currentPage = 1;
    @ViewChild('InputPagination') InputPagination: PaginationComponent;

    @Input()
    isAddRow: boolean = true;

    loader = 'actionloader';

    @Input() _gridHeight: any = '400px';

    @Input() disabledAddRowbtn: boolean = false;
    @Input() hideAddRowbtn: boolean = true;

    @Input() defaultValues: any[] = [];
    @Input() treeFromRelation: any = "";
    @Input() treeToRelation: any = "";
    @Input() itemsPerPage = 20;
    @Input() itemOptionsPerPage = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

    private dropdoewn: BizDropdownComponent = null;

    async dropdownInitialized(e, rowIndex, prop, item, row) {
        if (e && e.value) {
            item.dropdownData = e.data;
            await this.valueChange(e.value, rowIndex, prop, item, row)
        }
        // this.dropdoewn = component; 
    }
    async datalistInitialized(e, rowIndex, prop, item, row) {
        if (e && e.value) {
            item.dropdownData = e.data;
            await this.valueChange(e.value, rowIndex, prop, item, row)
        }
        // this.dropdoewn = component; 
    }

    async ngAfterViewInit() {
        this.initialized.emit(this);
    }

    reload() {
        // Do something
        return this;
    }

    // @Input() rows: any[] = [];
    @Input() headerHeight: number = 30;
    @Input() scrollbarH: boolean = true;
    @Input() scrollbarV: boolean = false;
    @Input() footerHeight: number = 100;
    @Input() limit: number = 10;
    // @Input() type: number = Enum.DataTable_Type.InputTable;
    @Input() hideEdit: boolean = true;
    @Input() hideView: boolean = false;
    @Input() hideDelete: boolean = true;
    @Input() columnMode: string = Enum.ColumnMode.standard;
    @Input() actionColumnWidth: number = 80;
    @Input() customColumnWidth: number = 80;
    // @Input() pagination: boolean = true;
    @ViewChild('table') table: BizDatatableComponent;
    @ViewChild('table') mainTable: DatatableComponent;
    @Input() addRows: number = 1;
    // @Input() customButtonList: any[] = [];
    @Input() isAction: boolean = false;
    @Input() isCustomButton: boolean = false;
    @Input() hideSave: boolean = false;
    @Input() CustomColumnName: string = "";
    @Input() groupRowsBy: string = "";
    @Input() groupRowsByCaption: string = "";
    @Input() groupRowExpand: boolean = false;

    public _columns: any[] = [];
    @Input()
    get columns(): any {

        return this._columns;
    }
    set columns(columns: any) {

        this._columns = columns;

        if (this.type === 5 && this._columns.length > 0) {

            let selectCol = {
                prop: 'selected',
                name: '',
                sortable: false,
                canAutoResize: false,
                draggable: false,
                resizable: false,
                headerCheckboxable: true,
                checkboxable: true,
                frozenLeft: 'frozenLeft',
                width: 50
            };
            let check = this._columns.filter(i => i.prop === 'selected')[0];
            if (!check)
                this._columns.splice(0, 0, selectCol);
        }

    }

    public _rows: any[] = [];
    @Input()
    get rows(): any {
        return this._rows;
    }
    set rows(row: any) {
        this._rows = row;

    }

    public _customButtonList: any[] = [];
    @Input()
    get customButtonList(): any {

        return this._customButtonList;
    }
    set customButtonList(customButtonList: any) {

        this._customButtonList = customButtonList;

    }

    public _pagination: boolean = true;
    @Input()
    get pagination(): any {

        return this._pagination;
    }
    set pagination(val) {

        this._pagination = val;

        if (val === true) {
            this.scrollbarV = false;
            this._gridHeight = 'auto'
            this.footerHeight = 100
        } else {
            this.scrollbarV = true
            this.footerHeight = 0
        }
    }

    public _type: number = Enum.DataTable_Type.InputTable;
    @Input()
    get type(): any {
        return this._type;
    }
    set type(type: any) {
        this._type = type;
    }

    @Output()
    onButtonClick: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    onEdit: EventEmitter<any> = new EventEmitter<any>();
    @Output()
    onView: EventEmitter<any> = new EventEmitter<any>();
    @Output()
    onDelete: EventEmitter<any> = new EventEmitter<any>();
    @Output()
    onChange: EventEmitter<any> = new EventEmitter<any>();
    @Output()
    onClick: EventEmitter<any> = new EventEmitter<any>();
    @Output()
    onSelection: EventEmitter<any> = new EventEmitter<any>();
    @Output()
    onCustomButtonClick: EventEmitter<any> = new EventEmitter<any>();
    @Output()
    onCellValueChange: EventEmitter<any> = new EventEmitter<any>();
    @Output()
    BeforeValueChange: EventEmitter<any> = new EventEmitter<any>();
    @Output()
    AfterAddRow: EventEmitter<any> = new EventEmitter<any>();
    @Output()
    BeforeAddRow: EventEmitter<any> = new EventEmitter<any>();
    @Output()
    BeforeOpenLister: EventEmitter<any> = new EventEmitter<any>();
    @Output()
    onTreeAction: EventEmitter<any> = new EventEmitter<any>();
    addRowDetail: any;
    async ngOnInit() {
        if (this.rows.length > 0 && this._type === 1) {
            this.addRowDetail = this.rows[0];
        }
    }

    buttonClick(row) {
        let obj = {
            grid: this.table,
            row: row
        }
        this.onButtonClick.emit(obj);
    }

    Edit(row, rowIndex: any) {
        let obj = {
            grid: this.table,
            row: row,
            index: rowIndex,
            loader: this.loader,
            component: this
        }
        this.onEdit.emit(obj);
    }

    View(row) {
        let obj = {
            grid: this.table,
            row: row,
            // index: rowIndex,
            loader: this.loader
        }
        this.onView.emit(obj);
    }

    Delete(row, rowindex?: any) {
        let obj = {
            grid: this.table,
            row: row,
            index: rowindex,
            loader: this.loader
        }

        // if (this._type === 1) {

        // let arr = this._rows;
        // arr.splice(rowindex, 1);
        // this.rows = [...arr] //.splice(rowindex, 1);


        // }
        this.onDelete.emit(obj);
    }

    changeValue(event, row) {
        if (event && row) {
            let obj = {
                column: event,
                row: row
            }
            this.onChange.emit(obj);
        }
    }

    @Input() public selectedRows: any[] = [];

    Click(row) {

        if (row) {
            let obj = {
                grid: this.table,
                mainTable: this.mainTable,
                row: row
            }
            this.onClick.emit(obj);
        }
    }

    async addRowsAgainstValue(rowIndex) {

        for (let index = 0; index < this.addRows; index++) {


        }
    }

    Selection(rows) {

        if (rows && rows.selected) {

            this.onSelection.emit(rows.selected);
            return this.selectedRows = rows.selected;

        }
    }

    valueChange(value, rowIndex, prop, item?: any, row?: any, dectection: boolean = false) {
        if (value && value.target && value.target.value) {
            value = value.target.value;
        }
        else if (value && value.value) {
            value = value.value;
        }
        let obj = {
            value: value,
            prop: prop,
            index: rowIndex,
            grid: this.table,
            row: row,
            item: item,
            loader: this.loader,
            component: this,
            detection: dectection
        }
        this.BeforeValueChange.emit(obj)
        if (value && prop) {
            this.rows[rowIndex][prop] = value;

            var dependent = this._columns.filter(i => i.isdependent !== undefined);

            if (dependent && dependent.length && dependent.length > 0) {


                dependent.forEach(element => {

                    if (element.isdependent === prop && value && (element.type === 6 || element.type === 2 || element.type === 11)) {

                        let val: any;
                        if (element.parameters) {
                            for (let index = 0; index < element.parameters.length; index++) {
                                const el = element.parameters[index];
                                if (el.value !== 'isdependent') {
                                    val = val ? val + '&' + el.name + '=' + el.value : el.name + '=' + el.value
                                } else {
                                    val = val ? val + '&' + el.name + '=' + value : el.name + '=' + value
                                }

                            }
                        }

                        let module = element.apiModuleName ? element.apiModuleName : undefined

                        var drop: any = document.getElementById(element.prop + rowIndex);

                        setTimeout(() => {
                            var loader: any = document.getElementById(this.loader + rowIndex);

                            if (loader) {

                                loader.classList.add('show-spinner');
                            }
                            this.buttonDisabled = true;
                            // this.buttonState = 'show-spinner';

                            this.baseService.get(element.apiController, element.apiRoute, val, module, false).then(o => {

                                if (o && o.length && o.length > 0) {

                                    element.currentDropDownData = o;
                                    if (!drop) {

                                        drop = document.getElementById(element.prop + rowIndex);
                                    }
                                    if (drop) {

                                        if (element.type === 6 || element.type === 2)
                                            drop.children[0].children[0].options.length = 0;
                                        else if (element.type === 11) {

                                            if (drop.children[0].children[1].options.length > 0) {
                                                let option = drop.children[0].children[1].options;
                                                let optionLength = drop.children[0].children[1].options.length;

                                                for (let opin = 0; opin < optionLength; opin++) {
                                                    const element = option[0];

                                                    element.remove();

                                                }
                                            }

                                        }

                                        for (let index in o) {

                                            if (element.type === 6 || element.type === 2)
                                                drop.children[0].children[0].options[drop.children[0].children[0].options.length] = new Option(o[index][element.displayMember], o[index][element.valueMember]);
                                            else if (element.type === 11) {
                                                var option = document.createElement('option');
                                                option.value = o[index][element.valueMember];
                                                drop.children[0].children[1].appendChild(option);
                                            }

                                        }
                                        // drop.children[0].children[0].options = o;
                                        if (element.type === 6 || element.type === 2)
                                            drop.children[0].children[0].value = Number(row[element.prop]);
                                        else if (element.type === 11)
                                            drop.children[0].children[1].value = Number(row[element.prop]);
                                    }
                                } else {
                                    if (drop) {
                                        if (element.type === 6 || element.type === 2)
                                            drop.children[0].children[0].options.length = 0;
                                        else if (element.type === 11) {
                                            if (drop.children[0].children[1].options.length > 0) {
                                                let option = drop.children[0].children[1].options;
                                                let optionLength = drop.children[0].children[1].options.length;

                                                for (let opin = 0; opin < optionLength; opin++) {
                                                    const element = option[0];

                                                    element.remove();

                                                }
                                            }
                                        }
                                    }
                                }
                                this.buttonDisabled = false;
                                if (loader) {

                                    loader.classList.remove('show-spinner');
                                }
                                // this.buttonState = '';
                            }).catch(e => {
                                if (loader) {

                                    loader.classList.remove('show-spinner');
                                }
                            })
                            if (loader) {

                                loader.classList.remove('show-spinner');
                            }
                        }, 0);

                        // document.querySelectorAll(element.prop + ind + ' option').forEach(option => option.remove())

                    }
                });
            }

            if (item.mapFields) {

                setTimeout(() => {

                    this.mapFields(value, rowIndex, prop, item, row)
                }, 0);
            }
            if (item.isdefault) {
                let obj = {};
                if (item.keyMap && item.keyMap.length && item.keyMap.length > 0) {
                    item.keyMap.forEach(e => {

                        obj = {
                            [e.name]: item.dropdownData.filter(o => o[item.valueMember] === Number(value))[0][e.value]
                        }
                        if (this.defaultValues)
                            this.defaultValues.push(obj);
                        if (item.defaultValues)
                            item.defaultValues.push(obj);
                    });
                }
                else {
                    obj = {
                        [item.prop]: value,
                    }
                    if (this.defaultValues)
                        this.defaultValues.push(obj);
                    if (item.defaultValues)
                        item.defaultValues.push(obj);
                }

            }
            this.onCellValueChange.emit(obj);
        }
        else if (!item.isdependent) {
            if (!value) this.rows[rowIndex][prop] = '';
            this.onCellValueChange.emit(obj);
        } else {
            this.rows[rowIndex][prop] = '';
        }
    }
    tempRows: any[] = [];
    async addGridRow() {
        if (!this.disabledAddRowbtn) {
            if (this._type === 1) {

                this.BeforeAddRow.emit(this);

                setTimeout(() => {

                    if (this.isAddRow) {

                        let keys: any = {};
                        // && Object.keys(this.customRow).length === 0
                        if (this._columns.length > 0) {
                            Object.keys(this._columns).forEach((i: any) => {
                                keys[this._columns[i]['prop']] = '';
                            })
                        }

                        this.tempRows = this.rows;
                        this.rows = [];

                        let ind = 0;

                        if (this.tempRows.length === 0) {

                            ind = this.mainTable.bodyComponent.getRowIndex(keys);
                        } else {

                            ind = this.mainTable.bodyComponent.getRowIndex(this.tempRows[this.tempRows.length - 1]) + 1
                        }

                        this.tempRows.splice(ind, 0, keys) //= [...arr];
                        this.rows = [...this.tempRows]
                        setTimeout(() => {
                            var dependent = this._columns.filter(i => i.isdependent !== undefined);

                            if (dependent && dependent.length && dependent.length > 0) {

                                dependent.forEach(element => {

                                    var drop: any = document.getElementById(element.prop + ind);

                                    if (element.type === 6 || element.type === 2 && element.isdependent) {

                                        // document.querySelectorAll(element.prop + ind + ' option').forEach(option => option.remove())

                                        if (drop) {

                                            drop.children[0].children[0].options.length = 0;
                                        }
                                    }
                                    else if (element.type === 11 && element.isdependent) {

                                        if (drop.children[0].children[1].options.length > 0) {
                                            let option = drop.children[0].children[1].options;
                                            let optionLength = drop.children[0].children[1].options.length;

                                            for (let opin = 0; opin < optionLength; opin++) {
                                                const element = option[0];

                                                element.remove();

                                            }
                                        }

                                    }

                                });
                            }
                        }, 0);

                        this.AfterAddRow.emit(this);
                        setTimeout(() => {
                            if (this.InputPagination)
                                this.InputPagination.selectPage(this.InputPagination['_totalPages'])
                        }, 0);
                    }
                }, 0);
            }
        }
    }

    mapFields(value, rowIndex, prop, item?: any, row?: any) {

        let arr = this.rows;
        if (value && prop && item && item.mapFields) {

            item.mapFields.forEach((element: MapConfiguration) => {

                var data;
                if (item.dropdownData && item.dropdownData.length && item.dropdownData.length > 0) {
                    data = item.dropdownData.filter((o: any) => o[item.valueMember] === Number(value))[0];
                }
                else if (item.currentDropDownData && item.currentDropDownData.length && item.currentDropDownData.length > 0) {
                    data = item.currentDropDownData.filter((o: any) => o[item.valueMember] === Number(value))[0];
                }

                if (data) {

                    const val = data[element.mapField];

                    // if (val) {
                    this.rows[rowIndex][element.field] = val;

                    let obj = {
                        value: val,
                        prop: element.field,
                        index: rowIndex,
                        grid: this.table,
                        row: row,
                        item: this._columns.filter(i => i.prop === element.mapField)[0],
                        loader: this.loader,
                        component: this
                    }
                    this.onCellValueChange.emit(obj);
                    // }
                }

            });

        }
    }

    setValue(item, value, rowIndex, row) {
        if (item) {
            if (item.type === 3 && value) {
                value = value.toString().substring(0, 10)
            }
            this.rows[rowIndex][item.prop] = value;
            if (item.mapFields) {
                this.mapFields(value, rowIndex, item.prop, item)
            }
            // this.valueChange(value, rowIndex, item.prop, item, row)
            return value;
        }
    }

    customButtonClick(item, row) {
        let obj = {
            grid: this.table,
            row: row,
            item: item
        }
        this.onCustomButtonClick.emit(obj);
    }

    public async getDroprownDate(item, rowIndex) {

        if (item.isdependent) {

            if (this.rows && this.rows.length && this.rows.length > 0) {
                let val;
                if (this.rows[rowIndex] && this.rows[rowIndex][item.isdependent])
                    val = this.rows[rowIndex][item.isdependent]

            }
        }

        return item.dropdownDate;
    }

    async inputListerInitialized(e, rowIndex, prop, item, row) {

        if (e && e.value) {
            item.dropdownData = e.data;
            let bool: boolean = false;
            if (e && e.isChange) {
                bool = e.isChange
            }
            await this.valueChange(e.value, rowIndex, prop, item, row, bool)

            let deflist = this._columns.filter(i => i.isdefault !== undefined);

            if (deflist && deflist.length && deflist.length > 0 && e.defaultValues.length > 0) {

                deflist.forEach(element => {

                    let key = e.defaultValues.filter(o => o[element.prop] !== undefined);

                    let keyVal = key && key.length > 0 ? key[0][element.prop] : undefined;

                    if (keyVal) {

                        if (this.rows[rowIndex] && this.rows[rowIndex][element.prop])
                            this.rows[rowIndex][element.prop] = keyVal;

                    }

                });
            }
        }

    }

    async inputInitialized(e, rowIndex, prop, item, row) {

        if (e && e.value) {

            await this.valueChange(e.value, rowIndex, prop, item, row)

        }

    }

    async BeforeOpen(e, rowIndex, prop, item, row) {

        if (e) {

            let obj = {
                component: e,
                prop: prop
            }
            this.BeforeOpenLister.emit(obj);
        }
    }

    getRowClass = (row) => {
        return row.tp === "Main" ? 'row-1' : 'row-2'
    }

    generateRowClass = (row) => {
        if (row) {

        }
        // return row.tp === "Main" ? 'row-1' : 'row-2'
    }

    TreeAction(event: any) {
        this.onTreeAction.emit(event);
    }

    cellClassMethod = (e) => {

        if (e.row.deliveryDate && e.column.prop === "delDate") {

            let val = new Date(e.row.deliveryDate);
            let current = new Date();
            let current30 = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 30, new Date().getHours(), new Date().getMinutes());
            let current60 = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 60, new Date().getHours(), new Date().getMinutes());
            if (val <= current) {
                return "redCell"
            }
            else if (val <= current30) {
                return "greenCell"
            }
            else if (val <= current60) {
                return "yellowCell"
            } else {
                return " ";
            }
        } else {
            return ""
        }
    }

    toggleExpandGroup(group) {
        this.mainTable.groupHeader.toggleExpandGroup(group);
    }

    setHeight() {
        if (this._gridHeight !== "400px") {
            return this._gridHeight;
        } else {
            return "250px"
        }
    }
    tempData: any[] = [];
    async keyup(obj) {
        debugger
        const val = obj.value;
        debugger
        if (val === "" && this.tempData.length > 0) {

            this.rows = [...this.tempData]
            this.tempData = [];
        }

        if (this.tempData.length === 0) this.tempData = this.rows;

        this.rows = this.rows.filter(i => i[obj.item.prop].toString().toLowerCase().indexOf(val.toString().toLowerCase()) !== - 1);
    }

    async onChangeItemsPerPage(item) {
        this.itemsPerPage = item;
    }

    pageChanged(event: PageChangedEvent): void {
        this.currentPage = event.page;
    }

}
