import { AfterViewInit, Component, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { QuillEditorBase } from 'ngx-quill';

@Component({
    selector: 'biz-editor',
    templateUrl: 'biz-editor.component.html',
    styleUrls: ['biz-editor.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => BizEditorComponent),
            multi: true
        }
    ]
})
export class BizEditorComponent implements ControlValueAccessor, AfterViewInit {
    @ViewChild('quillEditor') quill?: QuillEditorBase;
    private onTouchedCallback!: () => void;
    private onChangeCallback!: (_: any) => void;

    async ngAfterViewInit() {
        setTimeout(() => {
            var editor: any = document.getElementById('quillEditor');

            if (editor && editor.children && editor.children.length > 0) {

                editor.children[0].style.height = this._height + 'px';
            }
        }, 420);
    }

    @Input()
    caption: string = "";

    private _name: string = "";
    @Input()
    get name(): string {

        return this._name;
    }
    set name(value: string) {

        this._name = value;
    }

    private innerValue: any = "";
    get value(): any {
        return this.innerValue;
    }

    @Input()
    set value(v: any) {
        if (v !== this.innerValue) {
            this.innerValue = v;
            this.onChangeCallback(v);
        }
    }

    private _fullpage: string = ""
    @Input()
    get fullpage(): string {

        return this._fullpage
    }
    set fullpage(value: string) {

        this._fullpage = value;
    }

    _type: number = 1;

    @Input()
    set type(type: number) {
        this._type = type;
    }
    get type(): number {
        return this._type
    }

    _height: number = 40;
    @Input()
    set height(type: number) {
        this._height = type;
        this.setHeight(this._height);
    }
    get height(): number {
        return this._height
    }

    async setHeight(height: any) {
        var editor: any = document.getElementById('quillEditor');

        if (editor && editor.children && editor.children.length > 0) {

            editor.children[0].style.height = height + 'px';
        }
    }

    writeValue(value: any) {
        if (value !== this.innerValue) {
            this.innerValue = value;
        }
    }

    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }
}
