// Angular Imports
import { NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';

// This Module's Components
import { BizSelectComponent } from './biz-select.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [
        NgSelectModule, FormsModule, ReactiveFormsModule, CommonModule
    ],
    declarations: [
        BizSelectComponent,
    ],
    exports: [
        BizSelectComponent,
    ]
})
export class BizSelectModule {

}
