<div class="customTabs">
    <app-list-page-header displayMode="list" [showOrderBy]="false" [showBtnDropDown]="false"
        [showDisplayOptions]="false">
    </app-list-page-header>
    <form [formGroup]="myForm">
        <accordion class="detail-tabs" [closeOthers]="false" [isAnimated]="true">
            <accordion-group heading="PPD Filter" [isOpen]="true">
                <div class="row">                   
                    <div class="col-lg-3">
                        <biz-input caption="Purchase Order #" (keydown.enter)="onEnter(myForm.controls['id'].value)"
                            formControlName="id" type="number" [design]="2">
                        </biz-input>
                    </div>
                    <div class="col-lg-6">
                        <div class="text-left">
                            <button type="button" class="btn btn-primary default mb-1 btn-xs pt-2" (click)="getDate()">
                                Fetch </button>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="text-right">
                            <button type="button" class="btn btn-primary default mb-1 btn-xs pt-2" (click)="AuthoizedSave()">
                                Authoize </button>
                        </div>
                    </div>
                </div>
            </accordion-group>
        </accordion>
    </form>
    <!-- <div class="row">
        <div class="col-lg-12">
            <div class="card profile-box flex-fill mb-4" style="min-height: 140px;">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-6">
                            <ul class="personal-info">
                                <li>
                                    <div class="title" style="color: #f03939; font-weight:bold">P.O #</div>
                                    <div class="text" style="color: #f03939; font-weight:bold">{{data.requestno}}</div>
                                </li>
                                <li>
                                    <div class="title">Dated</div>
                                    <div class="text">{{data.dateMade | date:'fullDate'}}</div>
                                </li>
                                <li>
                                    <div class="title" style="color: #f03939; font-weight:bold">Supplier</div>
                                    <div class="text" style="color:  #f03939; font-weight:bold">{{data.suppliername}}</div>
                                </li>

                                <li >
                                    <div class="title" style="color: #863a3a; font: size 14px; font-weight:bold; font-family:Verdana, Geneva, Tahoma, sans-serif;" >PO.Type</div>
                                    <div class="text" style="color: #863a3a; font: size 14px; font-weight:bold; font-family:Verdana, Geneva, Tahoma, sans-serif;" >{{data.potypes}}</div>
                                </li>
                                
                            </ul>
                        </div>
                        <div class="col-lg-6">
                            <ul class="personal-info">   
                                                            
                                <li>
                                    <div class="title" style="color:  #f03939; font-weight:bold;">Currency</div>
                                    <div class="text" style="color:  #f03939; font-weight:bold">{{data.exchRate}}</div>
                                </li>
                                <li>
                                    <div class="title" style="color:#f03939; font-weight:bold">Exch.Rate</div>
                                    <div class="text" style="color: #f03939; font-weight:bold">{{data.usdXRate}}</div>
                                </li>
                                <li>
                                    <div class="title">Review Dated</div>
                                    <div class="text">{{data.reviewDate | date:'fullDate'}}</div>
                                </li>
                                <li>
                                    <div class="title">Reviewer Name</div>
                                    <div class="text">{{data.users_Name}}</div>
                                </li>

                                <li>
                                    <div class="title">Authoize Name</div>
                                    <div class="text">{{data.authorizename}}</div>
                                </li>
                            </ul>
                        </div>

                        <div class="col-lg-12">
                            <div class="text-right">
                                <button type="button" class="btn btn-primary default mb-1 btn-xs pt-2" (click)="AuthoizedSave()">
                                    Authoize </button>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <div class="row invoice-angular">
        <div class="col-12 mb-5">
          <div class="card mb-5 invoice-contents">
            <div class="card-body d-flex flex-column justify-content-between">
              <div class="d-flex flex-column">
                <div class="d-flex flex-row justify-content-between pt-2 pb-2">
                  <div class="d-flex align-self-center">
                    <!-- <img src="https://coloredstrategies.com/mailing/vien.png" /> -->
                    <img src="../../../../../assets/img/nlg.png" /> 
                  </div>
                  
                  <div class="d-flex w-30 text-right align-self-center">
                    <p class="text" style="color:#068d86; font-weight:bold">Noor Leather Garment (Pvt). Ltd
                        Plot # 47, Sector 7A, Korangi Industrial Area, Karachi-74900 Pakistan<br />92 21 35054677-8 </p>
                  </div>
                  
                </div>
                <div class="border-bottom pt-4 mb-5"></div>
                
               
                <div class="d-flex flex-row justify-content-between mb-5">
                  <div class="d-flex flex-column w-70 mr-2 p-4 text-semi-muted bg-semi-muted">
                    <p class="mb-0" style="color:#340472; font-size:larger; font-weight:bold">Customer: {{data.customer}}</p>
                    <p class="mb-0" style="color:#340472; font-weight:bold">Destination: {{data.destination}}</p>
                  </div>
                  <div class="d-flex w-30 flex-column text-right p-4 text-semi-muted bg-semi-muted">
                    <p class="mb-0 text" style="color:#340472; font-size:larger; font-weight:bolder"> Invoice #: {{data.invoiceNo}} </p>
                    <p class="mb-0 text" style="color:#340472; font-size:small">{{data.invoiceDate | date:'fullDate'}}</p>
                  </div>
                </div>
                <div class="border-bottom pt-8 mb-8"></div>
                <div class="row">
                    <div class="col-lg-6">
                        <ul class="personal-info">
                            <li>
                                <div class="title" style="color:#0c001b; font-size:small; font-weight: bold">Agent Name</div>
                                <div class="text">{{data.agentName}}</div>
                            </li>

                            <li>
                                <div class="title" style="color:#0c001b; font-size:small; font-weight: bold">Bank</div>
                                <div class="text">{{data.bankName}}</div>
                            </li>

                            <li>
                                <div class="title" style="color:#0c001b; font-size:small; font-weight: bold">Payment Term</div>
                                <div class="text">{{data.paymentTerm}}</div>
                            </li>

                            <li>
                                <div class="title" style="color:#0c001b; font-size:small; font-weight: bold">Document submited</div>
                                <div class="text">{{data.docHBl}}</div>
                            </li>

                            <li>
                                <div class="title" style="color:#0c001b; font-size:small; font-weight: bold">E-Form:</div>
                                <div class="text">{{data.eformNO}}</div>
                            </li>

                            <li>
                                <div class="title" style="color:#0c001b; font-size:small; font-weight: bold">E-Form Dated</div>
                                <div class="text">{{data.eformDate | date:'fullDate'}}</div>
                            </li>


                            <li>
                                <div class="title" style="color:#0c001b; font-size:small; font-weight: bold">Gross Weight:</div>
                                <div class="text">{{data.grossweight}}</div>
                            </li>

                            <li>
                                <div class="title" style="color:#0c001b; font-size:small; font-weight: bold">Net Weight:</div>
                                <div class="text">{{data.netWeight}}</div>
                            </li>

                            <li>
                                <div class="title" style="color:#0c001b; font-size:small; font-weight: bold">Authorized By:</div>
                                <div class="text">{{data.autherizeBy}}</div>
 
                            </li>
                            .                            <li>
                                <div class="title" style="color:#0c001b; font-size:small; font-weight: bold">Authorized Date:</div>
                                <div class="text"> {{data.authoizeDate | date:'fullDate'}}</div>
 
                            </li>
                           
                        </ul>
                    </div>
                    <div class="col-lg-6">

                 
                        <ul class="personal-info">
                            <li>
                                <div class="title" style="color:#0c001b; font-size:small; font-weight: bold">Vessel Name</div>
                                <div class="text">{{data.vesselName}}</div>
                            </li>
                            <li>
                                <div class="title" style="color:#0c001b; font-size:small; font-weight: bold;">Instruction </div>
                                <div class="text">{{data.specialInst}}</div>
                            </li>


                            <li>
                                <div class="title" style="color:#0c001b; font-size:small; font-weight: bold">Courier</div>
                                <div class="text">{{data.courier}}</div>
                            </li>
                            <li>
                                <div class="title" style="color:#0c001b; font-size:small; font-weight: bold;">ShipBy </div>
                                <div class="text">{{data.shipBy}}</div>
                            </li>

                            <li>
                                <div class="title" style="color:#0c001b; font-size:small; font-weight: bold">HBL #:</div>
                                <div class="text">{{data.hblNo}}</div>
                            </li>

                            <li>
                                <div class="title" style="color:#0c001b; font-size:small; font-weight: bold">E.G.M #</div>
                                <div class="text">{{data.egMNO}}</div>
                            </li>

                            <li>
                                <div class="title" style="color:#0c001b; font-size:small; font-weight: bold">Shipment Mode</div>
                                <div class="text">{{data.paymentType}}</div>
                            </li>
                            
                            <li>
                                <div class="title" style="color:#0c001b; font-size:small; font-weight: bold">Reviwed By:</div>
                                <div class="text">{{data.reviewedby}}</div>
                            </li>
                            <li>
                                <div class="title" style="color:#0c001b; font-size:small; font-weight: bold">Reviwed By:</div>
                                <div class="text">{{data.reviewDate | date:'fullDate'}}</div>
                            </li>
                        </ul>
                    </div>

                  
              </div>
              <div class="d-flex flex-column">
                <div class="border-bottom pt-3 mb-5"></div>
                <table class="table table-borderless d-flex justify-content-end">
                  <tbody>
                    
                    <tr>
                      <td class="text" style="color:#df0404; font-size:small; font-weight: bold;">No of cortons :</td>
                      <td class="text-right" style="color:#570101; font-size:large; font-weight: bold;">{{data.noofCotton}}</td>
                    </tr>
                    <tr>
                      <td class="text" style="color:#df0404; font-size:small; font-weight: bold;">Shipping Pcs :</td>
                      <td class="text-right" style="color:#570101; font-size:large; font-weight: bold;">{{data.noofPcs}}</td>
                    </tr>
                    <tr>
                        <td class="text" style="color:#df0404; font-size:small; font-weight: bold;">Currency :</td>
                        <td class="text-right" style="color:#570101; font-size:large; font-weight: bold;">{{data.currecy}}</td>
                      </tr>
                    <tr class="font-weight-bold">
                      <td class="text" style="color:#df0404; font-size:small; font-weight: bold;">Total Value :</td>
                      <td class="text-right" style="color:#570101; font-size:large; font-weight:bold;">{{data.billingValuem}}</td>
                    </tr>
                  </tbody>
                </table>
              
            </div>
          </div>
        </div>
      </div>
        </div>
    </div>
    
    <div class="row">
        <div class="col-lg-12">
            <div class="card profile-box flex-fill mb-2">
                <div class="card">
                    

                    <biz-datatable [hideAddRowbtn]="false" #dtltable [rows]="detailList"
                            [columns]="columnDef" limit="20"
                        >
                    </biz-datatable>

                                <!-- </thead>
                            </table>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
      

<simple-notifications></simple-notifications>

<ng-template #detailModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{ModalName}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-lg-12">
                <biz-datatable #tableContrast  [hideAddRowbtn]="false" [rows]="gridData"
                    [columns]="gridColumn" (onClick)="Action($event)">
                </biz-datatable>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="submit" class="btn btn-primary float-right" (click)="export()">Export</button>
    </div>
</ng-template>
<style>
    .edit-icon-review {
        background-color: #db3838;
        border: 1px solid #802121;
        border-radius: 24px;
        color: #fff;
        float: right;
        font-size: 12px;
        line-height: 24px;
        min-height: 26px;
        text-align: center;
        cursor: pointer;
        padding: 0px 15px;
    }

    .profile-view {
        position: relative;
    }

    .profile-view .profile-img-wrap {
        height: 120px;
        width: 120px;
    }

    .profile-img-wrap {
        height: 120px;
        position: absolute;
        width: 120px;
        background: #fff;
        overflow: hidden;
    }

    .profile-view .profile-img {
        width: 120px;
        height: 120px;
    }

    .profile-view .profile-img {
        width: 120px;
        height: 120px;
    }

    .profile-img-wrap img {
        border-radius: 50%;
        height: 120px;
        width: 120px;
    }

    .profile-view .profile-basic {
        margin-left: 140px;
        padding-right: 50px;
    }

    .profile-view .profile-basic {
        margin-left: 140px;
        padding-right: 50px;
    }

    .user-name {
        color: #333;
    }

    .text-muted {
        color: #8e8e8e !important;
    }

    .text-muted {
        --bs-text-opacity: 1;
        color: #6c757d !important;
    }

    .staff-id {
        font-size: 14px;
        font-weight: 500;
        margin-top: 5px;
    }

    .staff-msg {
        margin-top: 30px;
    }

    .staff-msg {
        margin-top: 30px;
    }

    .personal-info li {
        margin-bottom: 10px;
    }

    .personal-info li .title {
        color: #4f4f4f;
        float: left;
        font-weight: 500;
        margin-right: 30px;
        width: 35%;
    }

    .profile-info-left-b {
        border-left: 2px dashed #ccc;
    }

    .personal-info li .text {
        color: #8e8e8e;
        display: block;
        overflow: hidden;
    }

    .profile-view .pro-edit {
        position: absolute;
        right: 0;
        top: 0;
    }

    .edit-icon {
        background-color: #eee;
        border: 1px solid #e3e3e3;
        border-radius: 24px;
        color: #bbb;
        float: right;
        font-size: 12px;
        line-height: 24px;
        min-height: 26px;
        text-align: center;
        width: 26px;
    }

    .profile-box {
        min-height: 250px;
    }

    .flex-fill {
        flex: 1 1 auto !important;
    }

    .card-title {
        color: #1f1f1f;
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 20px;
    }

    .experience-box {
        position: relative;
    }

    .experience-list {
        list-style: none;
        margin: 0;
        padding: 0;
        position: relative;
    }

    .experience-list::before {
        background: #ddd;
        bottom: 0;
        content: "";
        left: 8px;
        position: absolute;
        top: 8px;
        width: 2px;
    }

    .experience-list>li {
        position: relative;
    }

    .experience-list>li .experience-user {
        background: #fff;
        height: 10px;
        left: 4px;
        margin: 0;
        padding: 0;
        position: absolute;
        top: 4px;
        width: 10px;
    }

    .before-circle {
        background-color: #ddd;
        border-radius: 50%;
        height: 10px;
        width: 10px;
    }

    .experience-list>li .experience-content {
        background-color: #fff;
        margin: 0 0 20px 40px;
        padding: 0;
        position: relative;
    }

    .experience-list>li .experience-content .timeline-content {
        color: #9e9e9e;
    }

    .experience-list>li .experience-content .timeline-content a.name {
        color: #616161;
        font-weight: 700;
    }

    .experience-list>li .time {
        color: #bdbdbd;
        display: block;
        font-size: 12px;
        line-height: 1.35;
    }

    .avatar.avatar-xs {
        width: 24px;
        height: 24px;
    }

    .avatar {
        background-color: #aaa;
        border-radius: 50%;
        color: #fff;
        display: inline-block;
        font-weight: 500;
        height: 38px;
        line-height: 38px;
        margin: 0 10px 0 0;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        vertical-align: middle;
        width: 38px;
        position: relative;
        white-space: nowrap;
    }

    .avatar>img {
        border-radius: 50%;
        display: block;
        overflow: hidden;
        width: 100%;
    }

    @media only screen and (max-width: 575.98px) {
        .profile-img-wrap {
            position: relative;
            margin: 0 auto;
        }
    }

    @media only screen and (max-width: 575.98px) {
        .profile-view .profile-basic {
            margin-left: 0;
            padding-right: 0;
        }
    }

    @media only screen and (max-width: 575.98px) {
        .profile-info-left {
            text-align: center;
        }
    }

    @media only screen and (max-width: 767.98px) {
        .profile-info-left {
            border-right: none;
            border-bottom: 2px dashed #ccc;
            margin-bottom: 20px;
            padding-bottom: 20px;
        }
    }

    @media only screen and (max-width: 991.98px) {
        .profile-info-left {
            border-right: none;
            border-bottom: 2px dashed #ccc;
            margin-bottom: 20px;
            padding-bottom: 20px;
        }
    }
</style>



