// Angular Imports

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ChatComponent } from './chat.component';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { SimpleNotificationsModule } from "angular2-notifications";
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { LayoutContainersModule } from '../../../../containers/layout/layout.containers.module';
import { TranslateModule } from '@ngx-translate/core';
import { TabsModule } from 'ngx-bootstrap/tabs';


@NgModule({
    imports: [
        FormsModule, ReactiveFormsModule, CommonModule, AccordionModule, SimpleNotificationsModule.forRoot(),PerfectScrollbarModule,LayoutContainersModule, TranslateModule,
        TabsModule
    ],
    declarations: [
        ChatComponent,
    ],
    exports: [
        ChatComponent,
    ]
})
export class ChatModule {

}
