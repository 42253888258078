// Angular Imports
import { NgModule } from '@angular/core';

// This Module's Components
import { PpdControllerComponent } from './ppd-controller.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ComponentModule } from '../../@core/components/components.module';
import { PagesContainersModule } from '../../../../containers/pages/pages.containers.module';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { ComponentsCarouselModule } from '../../../../components/carousel/components.carousel.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ChatModule } from '../../applications/chat/chat.module';
import { TimelineModule } from '../../applications/timeline/timeline.module';
import { TabsModule } from 'ngx-bootstrap/tabs';



//import { NgbAccordionModule, NgbCollapseModule, NgbProgressbarModule, NgbRatingModule, NgbToastModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
    imports: [
        FormsModule, ReactiveFormsModule, CommonModule, ComponentModule, PagesContainersModule, AccordionModule, SimpleNotificationsModule.forRoot(),
        ComponentsCarouselModule, NgxDatatableModule,ChatModule, TimelineModule, TabsModule
        // ,NgbAccordionModule,NgbCollapseModule,NgbProgressbarModule,
        // NgbRatingModule,NgbToastModule
    ],
    declarations: [
        PpdControllerComponent,
    ],
    exports: [
        PpdControllerComponent,
    ]
})
export class PpdControllerModule {

}
