// Angular Imports
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// This Module's Components
import { BizDatalistComponent } from './biz-datalist.component';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [
        FormsModule,ReactiveFormsModule,CommonModule
    ],
    declarations: [
        BizDatalistComponent,
    ],
    exports: [
        BizDatalistComponent,
    ]
})
export class BizDatalistModule {

}
