import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BaseService } from '../../Services/base.service';
import { ServiceLocator } from '../../Services/locator.service';
import { BizDatatableComponent } from '../biz-datatable/biz-datatable.component';
import { Enum } from '../config';

@Component({
    selector: 'gp-lookup',
    templateUrl: 'gp-lookup.component.html',
})
export class GpLookupComponent implements OnInit, AfterViewInit {
    @Input() defaultValues: any[] = [];
    @Input() rows: any[] = [];
    @Input() headerHeight: number = 30;
    @Input() returnObject: any = {};
    @Input() scrollbarH: boolean = true;
    @Input() footerHeight: number = 50;
    @Input() limit: number = 10;
    @Input() hideEdit: boolean = true;
    @Input() hideView: boolean = false;
    @Input() hideDelete: boolean = true;
    @Input() columnMode: string = Enum.ColumnMode.standard;
    @Input() pagination: boolean = true;
    @Input() caption: string = "PO Leather";
    @Input() rowIndex: any;
    itemOptionsOrders: any;
    itemOrder: any;
    buttonDisabled = false;
    buttonState = '';
    // @Input() type: number = 1;

    @Input() public selectedRows: any[] = [];

    @ViewChild('lookupModal') lookupModal: TemplateRef<any>;
    @ViewChild('table') table: BizDatatableComponent;

    public _columns: any[] = [];
    public _type: number = 4;
    modalRef: any;
    @Input()
    get columns(): any {

        return this._columns;
    }
    set columns(columns: any) {

        this._columns = columns;
    }
    @Input()
    get type(): any {
        return this._type;
    }
    set type(type: any) {
        this._type = type;
        if (type === 5 && this._columns.length > 0) {
            let selectCol = {
                prop: 'selected',
                name: '',
                sortable: false,
                canAutoResize: false,
                draggable: false,
                resizable: false,
                headerCheckboxable: true,
                checkboxable: true,
                width: 50
            };
            this._columns.splice(0, 0, selectCol);
        }
    }

    private baseService: any;

    constructor(private modalService: BsModalService) {
        this.baseService = ServiceLocator.injector.get(BaseService);
    }

    @Output()
    onClick: EventEmitter<any> = new EventEmitter<any>();

    articlelist: any[] = [];
    head: any[] = [];
    articleParams: any[] = [];

    async getGPDetail(ListType, id) {
        let val = 'LocationID=' + localStorage.getItem('LocationID') + '&UserID=' + localStorage.getItem('UserID') + '&ListType=' + ListType + '&FKGPID=' + id;
        await this.baseService.get('AccGatePassTypeDetail', 'getAll', val).then(i => {
            if (i && i.length && i.length > 0) {
                this.rows = i;
            } else {
                this.rows = [];
            }
        })
    }

    async ngOnInit() {
        debugger
        this.itemOrder = { label: 'PO', value: 'po' };
        this.itemOptionsOrders = [
            { label: 'ID', value: 'orderID' },
            { label: 'Date', value: 'poDate' },
            { label: 'PO', value: 'po' },
        ];
        this.articleParams = [{ name: 'filed', value: 'name' }, { name: 'value', value: 'value' }]
    }

    async ngAfterViewInit() { }

    async open() {
        this.columns = [
            {
                name: 'ID',
                prop: 'id',
                width: 70,
            },
            {
                name: 'Item ID',
                prop: 'fkItem',
                width: 110,
            },
            {
                name: 'Lot #',
                prop: 'fklotNo',
                width: 150,
            },
            {
                name: 'Pcs',
                prop: 'pieces',
                width: 70,
            },
            {
                name: 'SqFt',
                prop: 'sqft',
                width: 70,
            },

            {
                name: 'Roll',
                prop: 'roll',
                width: 70,
            },
            {
                name: 'Rate',
                prop: 'rate',
                width: 70,
            },
            {
                name: 'Sale Rate',
                prop: 'sRate',
                width: 100,
            },
        ];
        
        if (this.defaultValues && this.defaultValues.length && this.defaultValues.length > 0) {
            
            let gpid = this.defaultValues[0].gpid;
            
            if (gpid) {
                
                await this.getGPDetail(0, gpid);
            }
        }
        setTimeout(() => {
            this.caption = this.caption && this.caption ? this.caption : 'Gate Pass Lookup'
            this.modalRef = this.modalService.show(this.lookupModal, {
                animated: true,
                backdrop: 'static',
                class: 'modal-lg',
            });

        }, 0);

    }

    @Input()
    public LookupId: String = "";

    Click(row) {

        let obj = {
            grid: this.table,
            row: row,
            data: this.rows
        }

        if (obj) {

            if (this.modalRef)
                this.modalRef.hide()
            this.onClick.emit(obj);
        }
    }

    onSelection(selection) {

        if (selection) {

        }
    }

    save() {

        if (this.selectedRows) {

            this.modalRef.hide();
            return this.selectedRows;

        }
    }

    itemsPerPageChange(e) {

    }
    tempData: any[] = []
    async searchByButton(obj) {

        if (obj && obj.value) {

            // await this.getAllLeather(3, 0, obj.item.value, obj.value)
        }

    }

    searchKeyUp(obj): void {

        const val = obj.event.target.value;

        if (val === "" && this.tempData.length > 0) {

            this.rows = [...this.tempData]
            this.tempData = [];
        }

        if (this.tempData.length === 0) this.tempData = this.rows;

        this.rows = this.rows.filter(i => i[obj.item.value].toString().toLowerCase().indexOf(val.toString().toLowerCase()) !== - 1);

    };


}

export interface LookupResult {
    lookupId: string;
    data: any;
}
