// Angular Imports
import { NgModule } from '@angular/core';

// This Module's Components
import { EmployeeInfoComponent } from './employee-info.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ComponentModule } from '../components.module';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BizSelectModule } from '../biz-select/biz-select.module';
import { BizInputModule } from '../biz-input/biz-input.module';

@NgModule({
    imports: [
        FormsModule, ReactiveFormsModule, CommonModule, AccordionModule, BizSelectModule, BizInputModule
    ],
    declarations: [
        EmployeeInfoComponent,
    ],
    exports: [
        EmployeeInfoComponent,
    ]
})
export class EmployeeInfoModule {

}
