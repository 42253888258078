import { Component} from '@angular/core';

@Component({
  selector: 'app-blog-side-video',
  templateUrl: './blog-side-video.component.html'
})
export class BlogSideVideoComponent  {

  constructor() { }



}
