import { Component, Input, OnDestroy } from '@angular/core';
import { BaseComponent } from '../../@core/BaseComponent/base.component';
import * as moment from 'moment';

@Component({
    selector: 'timeline',
    templateUrl: 'timeline.component.html',
})
export class TimelineComponent extends BaseComponent {
    selectedConversation: any;
    @Input() ppdid: number = 0;

    constructor() {
        super();
    }

    async ngOnInit() {
        if (this.ppdid > 0)
            await this.getChatList(this.ppdid)
    }

    chatlist: any[] = [];
    async getChatList(groupid) {
        if (Number(groupid) > 0) {
            let val = 'tType=0&' + 'UserID=' + localStorage.getItem('UserID') + '&GroupID=' + groupid;
            await this.get('Chat', 'getChatList', val, 'apiURL').then((i: any) => {
                if (i && i.length && i.length > 0) {
                    i.map(u => {
                        if (u.createDateTime) {
                            u.date = moment(u.createDateTime).format('DD-MMM-YYYY HH:mm')
                        }
                    })
                    
                    this.chatlist = i;
                    
                    this.selectedConversation = {};
                    this.selectedConversation = i;

                }
            })
        }
    };
}
