import { AfterViewInit, Component, ComponentFactory, ComponentFactoryResolver, ElementRef, EventEmitter, forwardRef, Input, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ControlBase } from '../config';
import { Broadcast } from '../../Services/broadcast.service';
import { ServiceLocator } from '../../Services/locator.service';
import { BaseService } from '../../Services/base.service';
import { IndentLookupComponent } from '../indent-lookup/indent-lookup.component';

@Component({
    selector: 'input-indent',
    templateUrl: 'input-indent.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputIndentComponent),
            multi: true,
        },
        {
            // Is an InjectionToken required by this class to be able to be used as an Validator
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => InputIndentComponent),
            multi: true,
        },
        {
            provide: ControlBase,
            useExisting: InputIndentComponent,
        },
    ],
})
export class InputIndentComponent extends ControlBase
    implements ControlValueAccessor, AfterViewInit, Validator {
    private baseService: any;
    protected lookupSubscription: Subscription;
    displayVal: string = '';
    @Input() defaultValues: any[] = [];
    constructor(private element: ElementRef,
        private resolver: ComponentFactoryResolver,
        private broadcast: Broadcast) {
        super()
        this.baseService = ServiceLocator.injector.get(BaseService);
    }

    @Output() initialized: EventEmitter<InputIndentComponent> = new EventEmitter<InputIndentComponent>();

    @ViewChild("indentlookup", { read: ViewContainerRef }) container: any;

    discloseValidatorChange: (() => void) | undefined;
    @Input()
    design: any = 1;

    @Input() minlength: number;

    patternOfElement: any;

    @Input() maxlength: number;

    @Input()
    disabled: boolean = false;

    @Input()
    caption: string = "";

    @Input()
    type: string = "text";

    @Input()
    rowIndex: any;

    @Input()
    apiController: string = "";

    @Input()
    apiRoute: string = "";

    @Input()
    apiModuleName: string = "";

    @Input()
    params: any[] = [];

    @Input()
    fieldName: string = "";

    @Input()
    valueMember: string = "";

    @Input()
    displayMember: string = "";

    _width: any;
    @Input()
    get width(): any {

        return this._width;
    }
    set width(v: any) {

        if (v) {

            this._width = v - 60 + 'px'
        }
    }

    @Input() isdependent: any;

    @Input() row: any;

    @Input()
    required: boolean = false;
    innerValue: any;

    controlEl: any
    errMsg: any;
    dirtytext: boolean = false;
    isInvalid: boolean = false;

    @Input()
    get Type(): string {
        return this.type;
    }

    _data: any[] = [];
    get data(): any[] {
        return this._data;
    }

    @Input()
    set data(value: any[]) {
        this._data = value;
    }

    async Modelchange() {

        var val = Number(this.value);
        if (!isNaN(val)) this.value = val;
        //this.value = Number(this.value);
    }

    @Input()
    config: any[] = [];

    set Type(value) {

        this.type = value;
        // Binding a pattern according to its type
        if (this.type === "url") {

            this.patternOfElement = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi
        }
        else if (this.type === "email") {

            this.patternOfElement = "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$";
        }
        else if (this.type === "ipAddress") {

            this.patternOfElement = "^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$";
        }
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            if (!this.value && this.type === 'date') {
                let currentDate = new Date().toISOString().substring(0, 10);
                this.value = currentDate;
            }

        }, 0);
        this.initialized.emit(this);
    }

    private onChange = (value: any) => { };
    private onTouched = () => { };

    writeValue(value: any) {
        if (value !== this.innerValue) {
            if (this.type === "date" && value) {
                value = value.toString().substring(0, 10)
            }
            this.innerValue = value;
        }
    }

    keyup(v) {
        this.value = '';
    }

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

    registerOnValidatorChange?(fn: () => void): void {
        this.discloseValidatorChange = fn;
    }

    get value(): any {
        return this.innerValue;
    }

    ondelete(event: any) {
        this.value = "";
    }


    @Input()
    set value(v: any) {

        if (!isNaN(Number(v))) {
            if (v !== this.innerValue) {
                if (this.data.length > 0) {
                    if (this.data.filter(i => i[this.valueMember] === Number(v))[0][this.valueMember]) {
                        this.displayVal = '';
                    }
                    else {
                        this.displayVal = v;
                    }

                }
                else {
                    this.displayVal = v;
                }
                this.innerValue = v;
                this.displayVal = '';
                this.onChange(v);

                if (this.data.length > 0) {
                    this.initialized.emit(this);
                }
            }
        } else {
            if (this.data.length === 0)
                this.displayVal = v;
        }


    }

    @ViewChild("input") TextBox?: ElementRef;

    validate(control: AbstractControl): ValidationErrors | null { // function validates control

        this.dirtytext = false
        this.controlEl = control

        // if ((this.innerValue === null) && this.dcsTextBox && control.errors && control.errors.required ) {

        if ((this.innerValue === null || this.innerValue === "" || this.innerValue === undefined) && this.TextBox && control.errors && control.errors.required) {
            this.errMsg = "error"

            this.isInvalid = true
            return { invalid: true }
        }
        else {

            this.errMsg = null
            this.isInvalid = false
        }
        return null;
    }

    onBlur() {

        var requiredField
        // check whether the control is required or not
        if (this.controlEl && this.controlEl.errors && this.controlEl.errors.required) {

            requiredField = this.controlEl.errors.required
        }
        if ((this.innerValue === null || this.innerValue === "" || this.innerValue === undefined) && this.TextBox && requiredField) {
            this.errMsg = "error"

            this.dirtytext = true
        }
        else {

            this.errMsg = null
        }
        // Validate according to Type (email,cnic,mobile,ipAddress,url)
        if (this.type === "email") {

            if (!this.innerValue.match(this.patternOfElement)) {

                this.dirtytext = true
                this.errMsg = 'should be in right format'
            }
        }
        else if (this.type === "url") {
            if (!this.innerValue.match(this.patternOfElement)) {
                this.dirtytext = true
                this.errMsg = 'should be in right format'
            }
        }
        else if (this.type === "ipAddress") {
            if (!this.innerValue.match(this.patternOfElement)) {
                this.dirtytext = true
                this.errMsg = 'should be in right format'
            }
        }
        // else {
        //   this.errMsg = null
        // }
        this.onTouched();
    }


    componentRef: any;
    async openLister() {
        // if (this.broadcast)
        //     this.lookupSubscription = this.broadcast
        //         .observable<LookupResult>("lookup")
        //         .subscribe((arg: any) => {
        //             switch (arg.value.lookupId) {
        //                 case this.fieldName:
        //                     this.value = arg.value.data[this.valueMember];
        //                     // this.triggerChanged();
        //                     break;
        //             }
        //         });
        this.container.clear();

        const factory: ComponentFactory<any> = this.resolver.resolveComponentFactory(
            IndentLookupComponent
        );

        this.componentRef = this.container.createComponent(factory);
        this.componentRef.instance.columns = this.config;
        this.componentRef.instance.rows = [];
        this.componentRef.instance.type = 4;
        this.componentRef.instance.caption = this.caption ? this.caption : '';
        this.componentRef.instance.defaultValues = this.defaultValues;
        this.componentRef.instance.rowIndex = this.rowIndex;
        this.componentRef.instance.onClick.subscribe((val: any) => {

            if (val && val.data) {
                this.displayVal = '';
                this.data = val.data
            }

            if (val && val.row) {
                this.value = val.row.row[this.valueMember];
                // this.Modelchange();
                // this.triggerChanged();

            }
        });

        this.componentRef.instance.open();

    }

}
