import { AfterViewInit, Injectable, Injector, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable } from 'rxjs';
import { BaseService } from '../Services/base.service';
import { ServiceLocator } from '../Services/locator.service';
import { UserRole } from '../../../../shared/auth.roles';
import { NavigationExtras, Router } from '@angular/router';

@Injectable({
    providedIn: "root",
})
export abstract class BaseComponent implements OnInit, AfterViewInit {
    public formBuilder: any;
    public myForm: FormGroup;
    public loader: any;
    public masterState: string = '';
    public masterDisabled = false;
    public listingState: string = '';
    public listingDisabled = false;
    // public isLoader: boolean = true;
    newTabRouter: any;
    public baseService: any;
    constructor() {
        this.formBuilder = ServiceLocator.injector.get(FormBuilder);
        this.baseService = ServiceLocator.injector.get(BaseService);
        this.loader = ServiceLocator.injector.get(NgxUiLoaderService);
        this.newTabRouter = ServiceLocator.injector.get(Router);
    }

    async ngOnInit() { }

    async ngAfterViewInit() { }

    async save(controller, method, data, formControl?: FormGroup, list?: any, listKey?: any, moduleName?: any, isloader: boolean = true, isCheck: boolean = true) {
        debugger
        if (formControl) {
            this.myForm = formControl;
        }
        let rtn: string = "";
        let ctrl;
        let isDirty: boolean = false;
        let dirtycontrol: Array<AbstractControl> = [];
        if (this.myForm) {
            this.myForm.reset(this.myForm.value);
            Object.keys(this.myForm.controls).map((controlName) => {
                ctrl = this.myForm.get(controlName);
                if (ctrl) {
                    ctrl.markAsDirty({ onlySelf: true });
                    ctrl.markAsTouched({ onlySelf: true });
                }
                if (!isDirty) {
                    isDirty = ctrl?.errors !== null;
                } else {
                    if (ctrl) dirtycontrol.push(ctrl);
                }
            });
        }
        if (dirtycontrol && dirtycontrol.length && dirtycontrol.length > 0) {
            let invalidControl: any = document.getElementsByClassName(
                "ng-invalid"
            );
            if (invalidControl && invalidControl[1]) {
                invalidControl[1].focus();
            }
        }
        if (isCheck) {
            if (!isDirty && dirtycontrol.length === 0) {
                // if (this.loader) this.loader.start();
                if (list) {
                    data[listKey] = list;
                }
                return await this.baseService.post(controller, method, data, moduleName, isloader).then(x => {
                    return x;
                });
            } else {
                rtn = "Please fill required fields.";
            }
        } else {
            if (list) {
                data[listKey] = list;
            }
            return await this.baseService.post(controller, method, data, moduleName, isloader).then(x => {
                return x;
            });
        }
        return rtn;
    }
    async get(controller, method, params?: any, moduleName?: any, isloader: boolean = true) {
        return await this.baseService.get(controller, method, params, moduleName, isloader).then(x => {
            return x;
        });
    }
    async savingDetail(controller, method, data: any, moduleName?: any, isloader: boolean = true) {
        if (data) {
            return await this.baseService.post(controller, method, data, moduleName, isloader).then(x => {
                return x;
            });
        }
    }
    async delete(controller, method, key, moduleName?: any, isloader: boolean = true) {
        return await this.baseService.delete(controller, method, key, moduleName, isloader).then(x => {
            return x;
        });
    }
    async clear(formControl?: FormGroup) {
        if (formControl) {
            this.myForm = formControl;
        }
        if (this.myForm && this.myForm.controls) {
            Object.keys(this.myForm.controls).map((controlName) => {
                let ctr = this.myForm.get(controlName);
                if (ctr) {
                    this.myForm.controls[controlName].setValue(undefined);
                    ctr.markAsUntouched({ onlySelf: true });
                    ctr.markAsPristine({ onlySelf: true });
                }
            });
        }
    }
    public getMenusParent() {
        debugger
        let menu: any = localStorage.getItem('menus');
        menu = JSON.parse(menu);
        if (menu && menu.menus) {
            var result = menu.menus.filter(o => Number(o.parentID) === 0);
            if (result && result.length > 0) {
                for (let index = 0; index < result.length; index++) {
                    const main = result[index];
                    result[index].subs = [];
                    result[index].roles = [UserRole.Admin, UserRole.Editor];
                    var sub = menu.menus.filter(o => Number(o.parentID) === Number(main.id))
                    if (sub && sub.length > 0) {
                        result[index].subs = sub;
                        for (let ind = 0; ind < sub.length; ind++) {
                            // sub[index].roles = [];
                            const element = sub[ind];
                            // sub[index].roles = [UserRole.Admin, UserRole.Editor];
                            sub[ind].subs = [];
                            let subchild = menu.menus.filter(m => Number(m.parentID) === Number(element.id));
                            sub[ind].subs = subchild
                        }
                    }
                }
            }

            return result;
        }
    }

    async getMenusByID(id) {
        debugger
        let menu: any = localStorage.getItem('menus');

        menu = JSON.parse(menu);

        if (menu && menu.menus) {
            let module: any = menu.menus.filter(o => Number(o.parentID) === Number(id));

            if (id > 0 && module) {

                for (let index = 0; index < module.length; index++) {

                    const element = module[index];
                    module[index].subs = [];
                    module[index].roles = [UserRole.Admin, UserRole.Editor];
                    let subchild = menu.menus.filter(m => Number(m.parentID) === element.id);

                    module[index].subs = subchild
                }
                return module;
            }
        }
    }

    async postReport(controller, method, data, moduleName?: any, isloader: boolean = true) {

        return await this.baseService.postReport(controller, method, data, moduleName, isloader).then(x => {
            return x;
        });
    }
    async postRespone(controller, method, data, moduleName?: any, isloader: boolean = true) {

        return await this.baseService.postRespone(controller, method, data, moduleName, isloader).then(x => {

            return x;
        });
    }
    async openReportViewer(data) {
        debugger
        // let navigationExtras: NavigationExtras = {
        //     queryParams: {
        //         data: JSON.stringify(data),
        //     },
        //     replaceUrl: false,
        // };

        let obj = JSON.stringify(data);
        const url = this.newTabRouter.serializeUrl(this.newTabRouter.createUrlTree(['/reportViewer'], { queryParams: { obj } }));
        window.open(url, '_blank');
    }
    async openReportGridViewer(data) {

        let obj = JSON.stringify(data);
        const url = this.newTabRouter.serializeUrl(this.newTabRouter.createUrlTree(['/reportGridViewer'], { queryParams: { obj } }));
        window.open(url, '_blank');
    }

    async postMethod(controller, method, data, moduleName?: any, isloader: boolean = true) {
        debugger
        return await this.baseService.post(controller, method, data, moduleName, isloader).then(x => {
            return x;
        });
    }
}