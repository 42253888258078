<label style="display: flex">
    <div class="select-editable">
        <select class="listerselect listerselected" (keyup.backspace)="ondelete($event)"
            (keyup.delete)="ondelete($event)" [ngStyle]="{ 'width': _width }" style="border: none;"
            [disabled]="disabled" [(ngModel)]="value" (ngModelChange)="Modelchange()" #select="ngModel">
            <option *ngFor="let item of data" [value]="item[valueMember]">{{item[displayMember]}}</option>
        </select>
        <input type="text" name="format" [value]="displayVal" (keyup)="keyup($event.target.value)"
            style="background: transparent;margin-top: -2px;width: 100%;" />
    </div>
    <span style="padding-left: 4px; cursor: pointer;" (click)="openLister()"><i
            class="simple-icon-magnifier"></i></span>
</label>

<template #indentlookup></template>

<style>
    .listerDropdown {
        display: flex;

    }

    .listerselect {
        -webkit-appearance: none;
        -moz-appearance: none;
        text-indent: 1px;
        text-overflow: '';
    }

    .listerselected option {
        display: none;
    }

    .select-editable {
        position: relative;
        width: 120px;
        height: 18px;
    }

    .select-editable select {
        position: absolute;
        top: 0px;
        left: 0px;
        font-size: 14px;
        border: none;
        width: 120px;
        margin: 0;
    }

    .select-editable input {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100px;
        padding: 1px;
        font-size: 12px;
        border: none;
    }

    .select-editable select:focus,
    .select-editable input:focus {
        outline: none;
    }
</style>