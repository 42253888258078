// Angular Imports
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// This Module's Components
import { TimelineComponent } from './timeline.component';
import { CommonModule } from '@angular/common';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { LayoutContainersModule } from '../../../../containers/layout/layout.containers.module';

@NgModule({
    imports: [
        FormsModule, ReactiveFormsModule, CommonModule, AccordionModule, SimpleNotificationsModule.forRoot(),PerfectScrollbarModule,LayoutContainersModule
    ],
    declarations: [
        TimelineComponent,
    ],
    exports: [
        TimelineComponent,
    ]
})
export class TimelineModule {

}
