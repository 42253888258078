<!--component html goes here -->
<ng-container *ngIf="selectedConversation">
    <perfect-scrollbar class="scroll" [config]="{suppressScrollX: true, wheelPropagation: false}" #scroll>
        <ng-container *ngFor="let m of selectedConversation">
            <div class="card d-inline-block mb-3">
                <div class="position-absolute pt-1 pr-2 r-0">
                    <span class="text-extra-small text-muted">{{m.date}}</span>
                </div>
                <div class="card-body">
                    <div class="d-flex flex-row pb-2">
                        <a class="d-flex" href="javascript:;">
                            <img src="assets/img/profiles/groupIcon.png"
                                class="img-thumbnail border-0 rounded-circle mr-3 list-thumbnail align-self-center xsmall">
                        </a>
                        <div class=" d-flex flex-grow-1 min-width-zero">
                            <div
                                class="m-2 pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero">
                                <div class="min-width-zero">
                                    <p class="mb-0 truncate list-item-heading">{{m.users_Name}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="chat-text-left">
                        <p class="mb-0 text-semi-muted">{{m.chatMsg}} </p>
                    </div>
                </div>
            </div>
            <div class="clearfix"></div>
        </ng-container>
    </perfect-scrollbar>
</ng-container>