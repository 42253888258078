// Angular Imports
import { NgModule } from '@angular/core';

// This Module's Components
import { BizDropdownComponent } from './biz-dropdown.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [
        FormsModule,ReactiveFormsModule,CommonModule
    ],
    declarations: [
        BizDropdownComponent,
    ],
    exports: [
        BizDropdownComponent,
    ]
})
export class BizDropdownModule {

}
