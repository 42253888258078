import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Broadcast } from '../../Services/broadcast.service';
import { BizDatatableComponent } from '../biz-datatable/biz-datatable.component';
import { Enum } from '../config';
import { ServiceLocator } from '../../Services/locator.service';
import { BaseService } from '../../Services/base.service';

@Component({
    selector: 'acc-lookup',
    templateUrl: 'acc-lookup.component.html',
})
export class AccLookupComponent implements OnInit, AfterViewInit {
    @Input() defaultValues: any[] = [];
    @Input() rows: any[] = [];
    @Input() headerHeight: number = 30;
    @Input() scrollbarH: boolean = true;
    @Input() footerHeight: number = 50;
    @Input() limit: number = 10;
    @Input() hideEdit: boolean = true;
    @Input() hideView: boolean = false;
    @Input() hideDelete: boolean = true;
    @Input() columnMode: string = Enum.ColumnMode.standard;
    @Input() pagination: boolean = true;
    @Input() caption: string = "Accessories";
    @Input() rowIndex: any;
    itemOptionsOrders: any;
    itemOrder: any;
    buttonDisabled = false;
    buttonState = '';
    // @Input() type: number = 1;

    @Input() public selectedRows: any[] = [];

    @ViewChild('lookupModal') lookupModal: TemplateRef<any>;
    @ViewChild('table') table: BizDatatableComponent;

    public _columns: any[] = [];
    public _type: number = 4;
    modalRef: any;
    @Input()
    get columns(): any {

        return this._columns;
    }
    set columns(columns: any) {

        this._columns = columns;
    }
    @Input()
    get type(): any {
        return this._type;
    }
    set type(type: any) {
        this._type = type;
        if (type === 5 && this._columns.length > 0) {
            let selectCol = {
                prop: 'selected',
                name: '',
                sortable: false,
                canAutoResize: false,
                draggable: false,
                resizable: false,
                headerCheckboxable: true,
                checkboxable: true,
                width: 50
            };
            this._columns.splice(0, 0, selectCol);
        }
    }

    private baseService: any;

    constructor(private modalService: BsModalService, private broadcast: Broadcast) {
        this.baseService = ServiceLocator.injector.get(BaseService);
    }

    @Output()
    onClick: EventEmitter<any> = new EventEmitter<any>();

    store: any[] = [];
    head: any[] = [];

    lookupForm = new FormGroup({
        store: new FormControl(''),
        acchead: new FormControl('')
    });

    async getStoreData(listtype) {

        let loader;

        loader = document.getElementById('actionloader' + this.rowIndex);
        if (loader) {
            loader.classList.add('show-spinner');
        }
        let val = 'ListType=' + listtype;
        await this.baseService.get('SalesAccRequisition', 'getStoreList', val, 'Accessories', false).then(o => {
            if (loader) {
                loader.classList.remove('show-spinner');
            }
            if (o && o.length && o.length > 0) {
                this.store = o;
            }
        })
    }

    async FillAccHead(listtype, categoryid) {
        this.head = [];
        let val = 'ListType=' + listtype + '&CategoryID=' + categoryid;
        await this.baseService.get('SalesAccRequisition', 'FillAccHead', val, 'Accessories', false).then(o => {
            if (o && o.length && o.length > 0) {
                this.head = o;
            } else {
            }
        })
    }

    async ngOnInit() {
        this.itemOrder = { label: 'Accessory Name', value: 'name' };
        this.itemOptionsOrders = [
            { label: 'ID', value: 'id' },
            { label: 'Accessory Name', value: 'name' },
        ];
    }

    async FillAccName(mfield, fvalue) {
        let val;

        this.buttonState = 'show-spinner';
        this.buttonDisabled = true;

        if (this.lookupForm.controls['acchead'].value) {
            val = 'ListType=' + 2 + '&HeadID=' + this.lookupForm.controls['acchead'].value + '&mfiled=' + mfield + '&fvalue=' + fvalue;
        }
        else {
            val = 'ListType=' + 3 + '&HeadID=' + 0 + '&mfiled=' + mfield + '&fvalue=' + fvalue;
        }

        await this.baseService.get('SalesAccRequisition', 'FillAccName', val, 'Accessories', false).then(o => {
            this.buttonState = '';
            this.buttonDisabled = false;
            if (o && o.length && o.length > 0) {
                this.rows = o;
            } else {
                // return [];
            }
        })
    }

    async changeHead(id) {

        if (this.defaultValues && this.defaultValues.length && this.defaultValues.length > 0 && id) {
            this.defaultValues.map(i => {

                if (i && i.acchead) {

                    i.acchead = id;
                }
            })
        }

    }



    async ngAfterViewInit() { }

    async open() {

        this.columns = [{ prop: 'id', name: 'ID', width: 100 }, { prop: 'name', name: 'Accessory Name', width: 200 }];
        await this.getStoreData(1);
        
        if (this.defaultValues && this.defaultValues.length && this.defaultValues.length > 0) {
            
            this.defaultValues.forEach(element => {
                
                if (element && element.store) {
                    
                    this.lookupForm.controls['store'].setValue(element.store);
                }
                if (element && element.acchead) {
                    
                    this.lookupForm.controls['acchead'].setValue(element.acchead);
                }
            });
            
            if (this.lookupForm.controls['store'].value)
                await this.FillAccHead(1, this.lookupForm.controls['store'].value)
        }
        // if (this._columns.length > 0) {
        //     let arr: any[] = [];
        //     this._columns.map(i => {
        //         i.value = i.prop;
        //         i.label = i.name;

        //         if (i.prop !== 'selected')
        //             arr.push(i)
        //         return i;
        //     });

        //     this.itemOrder = arr[0];
        //     this.itemOptionsOrders = [...arr];
        // }


        setTimeout(() => {
            this.caption = this.caption && this.caption !== "" ? this.caption : 'Lookup'
            this.modalRef = this.modalService.show(this.lookupModal, {
                animated: true,
                backdrop: 'static',
                class: 'modal-lg',
            });

        }, 0);

    }

    @Input()
    public LookupId: String = "";

    Click(row) {

        let obj = {
            grid: this.table,
            row: row,
            data: this.rows
        }

        if (obj) {

            if (this.modalRef)
                this.modalRef.hide()
            // this.broadcast.publish<LookupResult>("lookup", <LookupResult>{
            //     lookupId: this.LookupId,
            //     data: row,
            // });

            this.onClick.emit(obj);
        }
    }
    

    onSelection(selection) {

        if (selection) {

        }
    }

    save() {

        if (this.selectedRows) {

            this.modalRef.hide();
            return this.selectedRows;

        }
    }

    itemsPerPageChange(e) {

    }
    tempData: any[] = []
    async searchByButtonAccessory(obj) {

        if (obj && obj.value) {

            await this.FillAccName(obj.item.value, obj.value)
        }

    }


}

export interface LookupResult {
    lookupId: string;
    data: any;
}
