<div class="card mb-4">
  <div class="card-body">
    <div class="d-flex flex-row mb-3">
      <a href="javascript:;">
        <img [src]="data.profilePic" [alt]="data.name"
          class="img-thumbnail border-0 rounded-circle list-thumbnail align-self-center xsmall" />
      </a>
      <div class="pl-3">
        <a href="javascript:;">
          <p class="font-weight-medium mb-0 ">{{data.name}}</p>
          <p class="text-muted mb-0 text-small">{{data.date}}</p>
        </a>
      </div>
    </div>
    <p>
      {{data.detail}}
    </p>
    <div class="position-relative">
      <app-player *ngIf="data.type === 'video'" class="video-js card-img video-content mb-3" [options]="{
          preload: 'auto',
          controls: true,
          poster: '/assets/img/video/poster.jpg',
          sources: [{src: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
          type: 'video/mp4'}]}">
      </app-player>
      <img *ngIf="data.type === 'image'" class="img-fluid border-0 border-radius mb-3 c-pointer" [src]="data.image"
        (click)="openLightbox(data.image)" />
    </div>
    <div>
      <div class="post-icon mr-3 d-inline-block"><a href="javascript:;"><i class="simple-icon-heart mr-1"></i></a>
        <span>{{data.likeCount}} Likes</span>
      </div>
      <div *ngIf="data.commentCount > 0" class="post-icon d-inline-block"><i class="simple-icon-bubble mr-1"></i>
        <span>{{data.commentCount}} Comment</span>
      </div>
    </div>

    <div class="mt-4">
      <div *ngIf="data.commentCount > 0" class="remove-last-border">
        <div *ngFor="let comment of data.comments" class="d-flex flex-row mb-3 border-bottom justify-content-between">
          <a href="javascript:;">
            <img [src]="comment.thumb" [alt]="comment.name"
              class="img-thumbnail border-0 rounded-circle list-thumbnail align-self-center xsmall" />
          </a>
          <div class="pl-3 flex-grow-1">
            <a href="javascript:;">
              <p class="font-weight-medium mb-0">{{comment.name}}</p>
              <p class="text-muted mb-0 text-small">{{comment.date}}</p>
            </a>
            <p class="mt-3">
              {{comment.detail}}
            </p>
          </div>
          <div class="comment-likes">
            <span class="post-icon"><a href="javascript:;"><span>{{comment.likes}} Likes</span> <i
                  class="simple-icon-heart ml-2"></i></a></span>
          </div>
        </div>
      </div>

      <div class="comment-contaiener">
        <div class="input-group">
          <input type="text" class="form-control" placeholder="Add a comment">
          <div class="input-group-append">
            <button class="btn btn-secondary" type="button"><span class="d-inline-block">Send</span> <i
                class="simple-icon-arrow-right ml-2"></i></button>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
