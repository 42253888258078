<div class="customTabs">
    <app-list-page-header displayMode="list" [showOrderBy]="false" [showBtnDropDown]="false"
        [showDisplayOptions]="false">
    </app-list-page-header>
    <form [formGroup]="myForm">
        <accordion class="detail-tabs" [closeOthers]="false" [isAnimated]="true">
            <accordion-group heading="PPD Filter" [isOpen]="true">
                <div class="row">                   
                    <div class="col-lg-3">
                        <biz-input caption="Purchase Order #" (keydown.enter)="onEnter(myForm.controls['id'].value)"
                            formControlName="id" type="number" [design]="2">
                        </biz-input>
                    </div>
                    <div class="col-lg-6">
                        <div class="text-left">
                            <button type="button" class="btn btn-primary default mb-1 btn-xs pt-2" (click)="getDate()">
                                Fetch </button>
                        </div>
                    </div>
                </div>
            </accordion-group>
        </accordion>
    </form>
    <div class="row">
        <div class="col-lg-12">
            <div class="card profile-box flex-fill mb-4" style="min-height: 140px;">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-6">
                            <ul class="personal-info">
                                <li>
                                    <div class="title" style="color: #f03939; font-weight:bold">P.O #</div>
                                    <div class="text" style="color: #f03939; font-weight:bold">{{data.requestno}}</div>
                                </li>
                                <li>
                                    <div class="title">Dated</div>
                                    <div class="text">{{data.dateMade | date:'fullDate'}}</div>
                                </li>
                                <li>
                                    <div class="title" style="color: #f03939; font-weight:bold">Supplier</div>
                                    <div class="text" style="color:  #f03939; font-weight:bold">{{data.suppliername}}</div>
                                </li>

                                <li >
                                    <div class="title" style="color: #863a3a; font: size 14px; font-weight:bold; font-family:Verdana, Geneva, Tahoma, sans-serif;" >PO.Type</div>
                                    <div class="text" style="color: #863a3a; font: size 14px; font-weight:bold; font-family:Verdana, Geneva, Tahoma, sans-serif;" >{{data.potypes}}</div>
                                </li>
                                
                            </ul>
                        </div>
                        <div class="col-lg-6">
                            <ul class="personal-info">   
                                                            
                                <li>
                                    <div class="title" style="color:  #f03939; font-weight:bold;">Currency</div>
                                    <div class="text" style="color:  #f03939; font-weight:bold">{{data.exchRate}}</div>
                                </li>
                                <li>
                                    <div class="title" style="color:#f03939; font-weight:bold">Exch.Rate</div>
                                    <div class="text" style="color: #f03939; font-weight:bold">{{data.usdXRate}}</div>
                                </li>
                                <li>
                                    <div class="title">Review Dated</div>
                                    <div class="text">{{data.reviewDate | date:'fullDate'}}</div>
                                </li>
                                <li>
                                    <div class="title">Reviewer Name</div>
                                    <div class="text">{{data.users_Name}}</div>
                                </li>

                                <li>
                                    <div class="title">Authoize Name</div>
                                    <div class="text">{{data.authorizename}}</div>
                                </li>
                            </ul>
                        </div>

                        <div class="col-lg-12">
                            <div class="text-right">
                                <button type="button" class="btn btn-primary default mb-1 btn-xs pt-2" (click)="AuthoizedSave()">
                                    Authoize </button>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
        </div>
    </div>
 
        <div class="row">
            <div class="col-lg-12">
                <div class="card profile-box flex-fill mb-2">
                    <div class="card">
                        <!-- <div class="card-headers">
                            <div class="d-flex align-items-center">
                                <h5 class="card-title flex-grow-1 mb-0">Order #VL2667</h5>
                            </div>
                        </div> -->
                        
                        <!-- <div class="card-body">
                            <div class="table-responsive table-card">
                                <table class="table table-nowrap align-middle table-borderless mb-0">
                                    <thead class="table-light text-muted">        -->

                       
                        <!-- <biz-datatable [hideAddRowbtn]="false" #dtltable [isAction]="true" [rows]="detailList"
                            [hideEdit]="false" [hideDelete]="false" [isCustomButton]="true"  [customButtonList]="customButtonList" [columns]="columnDef" limit="10"
                            (onEdit)="savingDtl($event)"  [isAction]="true" (onCustomButtonClick)="onCustomButtonClick($event)">
                        </biz-datatable> -->

                        <biz-datatable [hideAddRowbtn]="false" #dtltable [rows]="detailList"
                             [columns]="columnDef" limit="10"
                            >
                        </biz-datatable>

                                    <!-- </thead>
                                </table>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="col-lg-12">
            <biz-datatable #table [hideAddRowbtn]="false" (onCustomButtonClick)="onCustomButtonClick($event)"
                _gridHeight="150px" [pagination]="false" [isCustomButton]="true" [rows]="detailList"
                [customButtonList]="customButtonList" [columns]="columnDefIP">
            </biz-datatable>
        </div>
        <div class="col-lg-12 data-table-rows actionGrid rowColor grouping" *ngIf="accessoriesList.length > 0">
            <ngx-datatable #accTable id="accTable" [columnMode]="'force'" [virtualization]="false"
                [rows]="accessoriesList" [limit]="5" style="height: 350px;" [headerHeight]="30" [scrollbarH]="true"
                [scrollbarV]="true" [footerHeight]="0" [groupRowsBy]="'headAccessory'" [groupExpansionDefault]="false"
                [sorts]="[{ prop: 'title', dir: 'asc' }]">
                <ngx-datatable-group-header #myGroupHeader>
                    <ng-template let-group="group" let-expanded="expanded" ngx-datatable-group-header-template>
                        <div style="padding-left:5px;background: #e9e6e6;cursor: pointer;">
                            <a [class.datatable-icon-right]="!expanded" [class.datatable-icon-down]="expanded"
                                title="Expand/Collapse Group" (click)="toggleExpandGroup(group)">
                                <b style="font-size: 15px;">{{ group.value[0].headAccessory }}</b> ( <span> Total Items:
                                    {{group.value.length}} / Total Qty: {{getSum(group)}} </span> )
                            </a>
                        </div>
                    </ng-template>
                </ngx-datatable-group-header>
                <ngx-datatable-column *ngFor="let item of columnDefAccessories" [width]="item.width ? item.width : 150"
                    [name]="item.name" [prop]="item.prop">
                    <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                        <span style="cursor: pointer;" (click)="cellClickGroupBy(row, item.prop, item.name)"> {{value}}
                        </span>
                    </ng-template>
                </ngx-datatable-column>
            </ngx-datatable>

        </div> -->


     
   

<simple-notifications></simple-notifications>

<ng-template #detailModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{ModalName}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-lg-12">
                <biz-datatable #tableContrast [type]="4" [hideAddRowbtn]="false" [rows]="gridData"
                    [columns]="gridColumn" (onClick)="Action($event)">
                </biz-datatable>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="submit" class="btn btn-primary float-right" (click)="export()">Export</button>
    </div>
</ng-template>
<style>
    .edit-icon-review {
        background-color: #db3838;
        border: 1px solid #802121;
        border-radius: 24px;
        color: #fff;
        float: right;
        font-size: 12px;
        line-height: 24px;
        min-height: 26px;
        text-align: center;
        cursor: pointer;
        padding: 0px 15px;
    }

    .profile-view {
        position: relative;
    }

    .profile-view .profile-img-wrap {
        height: 120px;
        width: 120px;
    }

    .profile-img-wrap {
        height: 120px;
        position: absolute;
        width: 120px;
        background: #fff;
        overflow: hidden;
    }

    .profile-view .profile-img {
        width: 120px;
        height: 120px;
    }

    .profile-view .profile-img {
        width: 120px;
        height: 120px;
    }

    .profile-img-wrap img {
        border-radius: 50%;
        height: 120px;
        width: 120px;
    }

    .profile-view .profile-basic {
        margin-left: 140px;
        padding-right: 50px;
    }

    .profile-view .profile-basic {
        margin-left: 140px;
        padding-right: 50px;
    }

    .user-name {
        color: #333;
    }

    .text-muted {
        color: #8e8e8e !important;
    }

    .text-muted {
        --bs-text-opacity: 1;
        color: #6c757d !important;
    }

    .staff-id {
        font-size: 14px;
        font-weight: 500;
        margin-top: 5px;
    }

    .staff-msg {
        margin-top: 30px;
    }

    .staff-msg {
        margin-top: 30px;
    }

    .personal-info li {
        margin-bottom: 10px;
    }

    .personal-info li .title {
        color: #4f4f4f;
        float: left;
        font-weight: 500;
        margin-right: 30px;
        width: 35%;
    }

    .profile-info-left-b {
        border-left: 2px dashed #ccc;
    }

    .personal-info li .text {
        color: #8e8e8e;
        display: block;
        overflow: hidden;
    }

    .profile-view .pro-edit {
        position: absolute;
        right: 0;
        top: 0;
    }

    .edit-icon {
        background-color: #eee;
        border: 1px solid #e3e3e3;
        border-radius: 24px;
        color: #bbb;
        float: right;
        font-size: 12px;
        line-height: 24px;
        min-height: 26px;
        text-align: center;
        width: 26px;
    }

    .profile-box {
        min-height: 250px;
    }

    .flex-fill {
        flex: 1 1 auto !important;
    }

    .card-title {
        color: #1f1f1f;
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 20px;
    }

    .experience-box {
        position: relative;
    }

    .experience-list {
        list-style: none;
        margin: 0;
        padding: 0;
        position: relative;
    }

    .experience-list::before {
        background: #ddd;
        bottom: 0;
        content: "";
        left: 8px;
        position: absolute;
        top: 8px;
        width: 2px;
    }

    .experience-list>li {
        position: relative;
    }

    .experience-list>li .experience-user {
        background: #fff;
        height: 10px;
        left: 4px;
        margin: 0;
        padding: 0;
        position: absolute;
        top: 4px;
        width: 10px;
    }

    .before-circle {
        background-color: #ddd;
        border-radius: 50%;
        height: 10px;
        width: 10px;
    }

    .experience-list>li .experience-content {
        background-color: #fff;
        margin: 0 0 20px 40px;
        padding: 0;
        position: relative;
    }

    .experience-list>li .experience-content .timeline-content {
        color: #9e9e9e;
    }

    .experience-list>li .experience-content .timeline-content a.name {
        color: #616161;
        font-weight: 700;
    }

    .experience-list>li .time {
        color: #bdbdbd;
        display: block;
        font-size: 12px;
        line-height: 1.35;
    }

    .avatar.avatar-xs {
        width: 24px;
        height: 24px;
    }

    .avatar {
        background-color: #aaa;
        border-radius: 50%;
        color: #fff;
        display: inline-block;
        font-weight: 500;
        height: 38px;
        line-height: 38px;
        margin: 0 10px 0 0;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        vertical-align: middle;
        width: 38px;
        position: relative;
        white-space: nowrap;
    }

    .avatar>img {
        border-radius: 50%;
        display: block;
        overflow: hidden;
        width: 100%;
    }

    @media only screen and (max-width: 575.98px) {
        .profile-img-wrap {
            position: relative;
            margin: 0 auto;
        }
    }

    @media only screen and (max-width: 575.98px) {
        .profile-view .profile-basic {
            margin-left: 0;
            padding-right: 0;
        }
    }

    @media only screen and (max-width: 575.98px) {
        .profile-info-left {
            text-align: center;
        }
    }

    @media only screen and (max-width: 767.98px) {
        .profile-info-left {
            border-right: none;
            border-bottom: 2px dashed #ccc;
            margin-bottom: 20px;
            padding-bottom: 20px;
        }
    }

    @media only screen and (max-width: 991.98px) {
        .profile-info-left {
            border-right: none;
            border-bottom: 2px dashed #ccc;
            margin-bottom: 20px;
            padding-bottom: 20px;
        }
    }
</style>



