import { Component, Input, ViewChild, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { BaseService } from '../../Services/base.service';
import { ServiceLocator } from '../../Services/locator.service';
import { BizSelectComponent } from '../biz-select/biz-select.component';

@Component({
    selector: 'employee-info',
    templateUrl: 'employee-info.component.html',
})
export class EmployeeInfoComponent implements OnInit {

    @Input() employeeID: number = 0;

    empForm = new FormGroup({
        employeeID: new FormControl(),
        fullName: new FormControl(),
        oldCardNo: new FormControl(),
        department: new FormControl(),
        designation: new FormControl()
    });

    employeeParams: any[] = [];
    employeeData: any[] = [];

    @ViewChild('empSelect') empSelect: BizSelectComponent;

    private baseService: any;

    constructor() {
        this.baseService = ServiceLocator.injector.get(BaseService);
    }

    async displayInfo(id, e) {
        
        if (id > 0 && this.empSelect && this.empSelect.data && this.empSelect.data.length > 0) {
            
            let obj = this.empSelect.data.filter(o => o.employeeID === Number(id))[0];
            if (obj) {
                this.empForm.controls['employeeID'].setValue(obj.employeeID)
                this.empForm.controls['oldCardNo'].setValue(obj.oldCardNo)
                this.empForm.controls['department'].setValue(obj.department)
                this.empForm.controls['designation'].setValue(obj.designation)
            }
        }
    }

    async ngOnInit() {
        this.employeeParams = [
            { name: 'tType', value: '0' }, { name: 'flocation', value: localStorage.getItem('LocationID') },
            { name: 'USerID', value: localStorage.getItem('UserID') }, { name: 'filed', value: 'fullName' },
            { name: 'value', value: 'value' }
        ]
    }
}
