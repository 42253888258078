import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BaseService } from '../../Services/base.service';
import { ServiceLocator } from '../../Services/locator.service';
import { BizDatatableComponent } from '../biz-datatable/biz-datatable.component';
import { Enum } from '../config';

@Component({
    selector: 'inquiry-lookup',
    templateUrl: 'inquiry-lookup.component.html',
})
export class InquiryLookupComponent implements OnInit, AfterViewInit {
    @Input() defaultValues: any[] = [];
    @Input() rows: any[] = [];
    @Input() headerHeight: number = 30;
    @Input() returnObject: any = {};
    @Input() scrollbarH: boolean = true;
    @Input() footerHeight: number = 50;
    @Input() limit: number = 10;
    @Input() hideEdit: boolean = true;
    @Input() hideView: boolean = false;
    @Input() hideDelete: boolean = true;
    @Input() columnMode: string = Enum.ColumnMode.standard;
    @Input() pagination: boolean = true;
    @Input() caption: string = "Inquiry";
    @Input() rowIndex: any;
    itemOptionsOrders: any;
    itemOrder: any;
    buttonDisabled = false;
    buttonState = '';
    // @Input() type: number = 1;

    @Input() public selectedRows: any[] = [];

    @ViewChild('lookupModal') lookupModal: TemplateRef<any>;
    @ViewChild('table') table: BizDatatableComponent;

    public _columns: any[] = [];
    public _type: number = 4;
    modalRef: any;
    @Input()
    get columns(): any {

        return this._columns;
    }
    set columns(columns: any) {

        this._columns = columns;
    }
    @Input()
    get type(): any {
        return this._type;
    }
    set type(type: any) {
        this._type = type;
        if (type === 5 && this._columns.length > 0) {
            let selectCol = {
                prop: 'selected',
                name: '',
                sortable: false,
                canAutoResize: false,
                draggable: false,
                resizable: false,
                headerCheckboxable: true,
                checkboxable: true,
                width: 50
            };
            this._columns.splice(0, 0, selectCol);
        }
    }

    private baseService: any;

    constructor(private modalService: BsModalService) {
        this.baseService = ServiceLocator.injector.get(BaseService);
    }

    @Output()
    onClick: EventEmitter<any> = new EventEmitter<any>();

    async getAll(tType) {
        let val = 'LocationID=' + localStorage.getItem('LocationID') + '&UserID=' + localStorage.getItem('UserID') + '&ListType=' + tType;
debugger
        await this.baseService.get('SalesInquiry', 'getAll', val, 'apiURL').then(i => {
            debugger
            if (i && i.length && i.length > 0) {
                i.map(u => {
                    u.date = moment(u.tranDate).format('DD-MMM-YYYY');
                })
                this.rows = i;
            } else {
                this.rows = [];
            }
        })
    }

    async ngOnInit() {
        this.itemOrder = { label: 'Customer', value: 'cName' };
        this.itemOptionsOrders = [
            { label: 'ID', value: 'id' },
            { label: 'Dated', value: 'dated' },
            { label: 'Type', value: 'inqType' },
            { label: 'Customer', value: 'cName' },
            { label: 'Leather', value: 'leather' },
            { label: 'Artical', value: 'artical' },
            { label: 'Color', value: 'colour' },
            { label: 'Season', value: 'seasonName' },
            { label: 'Status', value: 'status' },

        ];
    }

    async ngAfterViewInit() { }
    async gridConfig() {
        this.columns = [
            {
                name: 'ID',
                prop: 'id',
                width: 90
            },
            {
                name: 'Date',
                prop: 'date',
                width: 110
            },
            {
                name: 'Type',
                prop: 'inqType',
                width: 100
            },
            {
                name: 'Customer',
                prop: 'cName',
            },

            {
                name: 'Leather',
                prop: 'leather',
            },

            {
                name: 'Artical',
                prop: 'artical',
            },

            {
                name: 'Color',
                prop: 'colour',
            },
            {
                name: 'Season',
                prop: 'seasonName',
            },
            {
                name: 'Status',
                prop: 'status',
            },
        ]
    }

    async open() {
        
        // this.columns = [{ prop: 'id', name: 'ID', width: 100 }, { prop: 'leather', name: 'Leather Name', width: 200 }];
        await this.getAll(0);
        await this.gridConfig();
        
        setTimeout(() => {
            // this.caption = this.caption && this.caption !== "" ? this.caption : 'Lookup'
            this.modalRef = this.modalService.show(this.lookupModal, {
                animated: true,
                backdrop: 'static',
                class: 'modal-lg',
            });

        }, 0);

    }

    @Input()
    public LookupId: String = "";

    Click(row) {

        let obj = {
            grid: this.table,
            row: row,
            data: this.rows
        }
        
        if (obj) {
            
            if (this.modalRef)
                this.modalRef.hide()
            this.onClick.emit(obj);
        }
    }

    onSelection(selection) {

        if (selection) {

        }
    }

    save() {

        if (this.selectedRows) {

            this.modalRef.hide();
            return this.selectedRows;

        }
    }

    itemsPerPageChange(e) {

    }
    tempData: any[] = []
    async searchByButton(obj) {

        let val = 'LocationID=' + localStorage.getItem('LocationID') + '&UserID=' + localStorage.getItem('UserID') + "&Mfiled=" + obj.item.value + "&" + "Fvalue=" + obj.value;
        if (obj.item.label === "All") {
            await this.getAll(0);
        } else {
            await this.baseService.get('SalesInquiry', 'getSearchby', val, 'apiURL').then((i: any) => {
                if (i && i.length && i.length > 0) {
                    i.map(o => {
                        o.date = moment(o.tranDate).format('DD-MMM-yyyy')
                    })
                    this.rows = i;
                } else {
                    this.rows = [];
                }
            })
        }
    }
    searchKeyUp(obj): void {

        const val = obj.event.target.value;

        if (val === "" && this.tempData.length > 0) {

            this.rows = [...this.tempData]
            this.tempData = [];
        }

        if (this.tempData.length === 0) this.tempData = this.rows;

        this.rows = this.rows.filter(i => i[obj.item.value].toString().toLowerCase().indexOf(val.toString().toLowerCase()) !== - 1);

    };

}

export interface LookupResult {
    lookupId: string;
    data: any;
}