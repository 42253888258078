<!--component html goes here -->

<ng-template #lookupModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{caption}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <app-list-page-header displayMode="list" [itemOptionsPerPage]="[2, 3, 4, 5, 10, 20]" [showDisplayMode]="false"
            [itemsPerPage]="15" [itemOptionsOrders]="itemOptionsOrders" [showBtnDropDown]="false" [isHeader]="false"
            (itemsPerPageChange)="itemsPerPageChange($event)" [itemOrder]="itemOrder" [showBtnAdd]="false"
            (searchKeyUp)="searchKeyUp($event)" [showItemsPerPage]="false"
            [btnSearch]="false"></app-list-page-header>
        <div class="row">
            <div class="col-12 mb-4 data-table-rows actionGrid" style="margin-bottom: 15px;">
                <biz-datatable #table [type]="_type" [rows]="rows" [limit]="limit" [columns]="_columns"
                    [footerHeight]="50" (onClick)="Click($event)" [selected]="selectedRows"
                    [selectionType]="'checkbox'" (onSelection)='onSelection($event)'>
                </biz-datatable>
            </div>
        </div>
    </div>
    <div class="modal-footer" *ngIf="type === 5">
        <button type="submit" class="btn btn-primary float-right" (click)="save()">Select</button>
    </div>
</ng-template>
