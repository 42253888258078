// Angular Imports
import { NgModule } from '@angular/core';

// This Module's Components
import { LookupComponent } from './lookup.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BizDatatableModule } from '../biz-datatable/biz-datatable.module';
// import { NgxDatatableModule } from '@swimlane/ngx-datatable';
// import { BsModalService } from 'ngx-bootstrap/modal';
// import { PaginationConfig } from 'ngx-bootstrap/pagination';
import { PagesContainersModule } from '../../../../../containers/pages/pages.containers.module';

@NgModule({
    imports: [
        FormsModule, ReactiveFormsModule, CommonModule, BizDatatableModule, PagesContainersModule
    ],
    declarations: [
        LookupComponent,
    ],
    exports: [
        LookupComponent,
    ],
    // providers: [BsModalService],
    // providers: [[{provide: PaginationConfig, useValue: { boundaryLinks: true,  firstText: 'First', previousText: '&lsaquo;', nextText: '&rsaquo;', lastText: 'Last', maxSize: 1 }}]]
})
export class LookupModule {

}
